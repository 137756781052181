import SettingsPolicies from '@/components/policies/settings-policies';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useParams } from 'react-router-dom';

export default function Policies() {
  const params = useParams();

  if (!params.restaurantSlug) {
    throw Error(`Edit Details page requires a restaurantSlug url param`);
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const restaurantData = restaurantQuery.data;

  // OLD POLICIES PAGE
  return !restaurantData ? null : <SettingsPolicies restaurantId={restaurantData.id} />
}
