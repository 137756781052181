// MediaUploads.tsx
import React, { useEffect, useState } from 'react';
import Draggable from '@/components/draggable/draggable';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

type DraggableMediaItem = {
  id: string;
  type: string;
  path: string;
  content: React.ReactNode;
};

interface MediaUploadsProps<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  initialItems: DraggableMediaItem[];
}

export default function MediaUploads<TFieldValues extends FieldValues>({
  form,
  name,
  initialItems,
}: MediaUploadsProps<TFieldValues>) {
  const [items, setItems] = useState<DraggableMediaItem[]>([]);

  useEffect(() => {
    if (initialItems && initialItems.length > 0) {
      setItems(initialItems);
    }
  }, [initialItems]);

  const handleDragEnd = (newItems: DraggableMediaItem[]) => {
    setItems(newItems);
    // Update form value
    const updatedMedia = newItems.map(({ id, content, ...rest }) => rest);
    form.setValue(name, updatedMedia as PathValue<TFieldValues, Path<TFieldValues>>, {
      shouldDirty: true,
    });
  };

  return <Draggable items={items} onDragEnd={handleDragEnd} />;
}
