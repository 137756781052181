import { EventList } from '@/components/events-list';
import LoadingSpinner from '@/components/loading-spinner';
import PageHeader from '@/components/page-header';
import ResizeHandle from '@/components/resize-handle/resize-handle';
import { useEvents } from '@/hooks/useEvents';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { Event } from '@repo/types';
import { Button, Card } from '@ui/components';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

export default function SpecialEvents() {
  const params = useParams();
  const location = useLocation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  if (!params.restaurantSlug) {
    throw Error('Could not find restaurantSlug url param');
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const eventsQuery = useEvents(restaurantQuery.data?.id || '');

  useEffect(() => {
    setIsSidebarVisible(!!params.eventId || location.pathname.endsWith('/create'));
  }, [location.pathname, params.eventId]);

  if (restaurantQuery.isLoading || eventsQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (restaurantQuery.isError || !restaurantQuery.data) {
    return <div>Restaurant not found</div>;
  }

  return (
    <PanelGroup direction="horizontal" autoSaveId="events-page">
      <Panel defaultSize={70}>
        <Card className="flex-1 w-full h-full">
          <main className="h-full overflow-y-auto">
            <div className="flex items-center justify-between">
              <PageHeader title="Special Events" />
              <div className="flex gap-2 px-5">
                <Button asChild>
                  <Link to="create">Create New</Link>
                </Button>
              </div>
            </div>
            <EventList events={(eventsQuery.data as Event[]) || []} restaurant={restaurantQuery.data} />
          </main>
        </Card>
      </Panel>

      <ResizeHandle />

      <AnimatePresence>
        {isSidebarVisible && (
          <Panel defaultSize={30} minSize={20} maxSize={50}>
            <motion.div
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              transition={{ duration: 0.3 }}
              className="w-full h-full"
            >
              <Card className="w-full h-full">
                {/* Outlet is the drawer that opens when selecting an item from the list */}
                <Outlet />
              </Card>
            </motion.div>
          </Panel>
        )}
      </AnimatePresence>
    </PanelGroup>
  );
}
