export type UpdateTableDto = Partial<Table>;
export interface Table {
  id: string;
  slug: string;
  name: string;
  internalName: string;
  label: TableType | string;
  location: TableLocation | string;
  tags: string[];
  attributes?: Attributes;
  clusterSize?: number;
  restaurantId: string;
  description: string;
  minGuests: number;
  maxGuests: number;
  capacity: string; // computed field on frontend
  earlyBirdPercentageDiscount?: number;
  defaultTimeSlots: { [time: string]: TimeSlot };
  closed: boolean;
  media?: MediaContent[];
  // imageUrls?: ImageContent[];
  exclusiveExperience?: boolean;
  sid?: string;
  pos: Vector3;
  tourStop?: number;
  pinType?: string;
  carouselImagePath?: string;
  timeBuffer?: TimeBuffer;
  disabled?: boolean;
  // enabled: boolean;
  deleted?: boolean;
  displayOrder?: number;
  partnershipDiscountType?: "flat" | "percent";
  partnershipDiscountAmount?: number;
  partnershipName?: string;
  isPatio?: boolean;
  supportLargeGroup?: boolean;
  largeGroupSettings?: LargeGroupSettings;
  model: string;
}

interface Attributes {
  // TODO: fix backend typo
  ambiance?: (AmbienceAttribute | string)[];
  view?: (ViewAttribute | string)[];
  // TODO: fix backend typo
  spacial?: (PrivacyAttribute | string)[];
}

export interface TimeSlot {
  regularPrice?: number;
  isPerGuest?: boolean;
  timeLabel: string;
  available: boolean;
  resDuration?: number;
  priceTier?: number;
  service?: string;
  seating?: string;
}

export interface MediaContent {
  type: MediaType;
  path: string;
}

// interface ImageContent {
//   // Define the shape of the ImageContent object here
// }

interface Vector3 {
  x: number;
  y: number;
  z: number;
}

interface TimeBuffer {
  minutesBefore?: number;
  minutesAfter?: number;
}

interface LargeGroupSettings {
  // Define the shape of the LargeGroupSettings object here
}

export enum TableType {
  TABLE = "table",
  CABANA = "cabana",
  POOL_BED = "pool bed",
}

export enum TableLocation {
  INDOORS = "indoors",
  OUTDOORS = "outdoors",
}


export enum AmbienceAttribute {
  ROMANTIC = "romantic",
  BUSINESS = "business",
  SOCIAL = "social",
}
export enum ViewAttribute {
  WATERFRONT = 'waterfront',
  SKYLINE = 'skyline',
  STREET = 'street',
  GARDEN = 'garden',
  SUNSET = 'sunset',
  BEACH = 'beach',
  KITCHEN = 'kitchen',
  STAGE = 'stage',
  NO_STAGE = 'no stage',
  LIMITED_STAGE = 'limited stage',
  FIREWORKS = 'fireworks',
  TELEVISION = 'television'
}
export enum PrivacyAttribute {
  PRIVATE = "private",
  SEMI_PRIVATE = "semi-private",
}
