export enum MediaType {
    IMAGE = 'image',
    VIDEO = 'video',
    UNKNOWN = 'unknown',
}

export enum MediaSource {
    MATTERPORT = 'matterport',
    UPLOAD = 'upload',
}

export type Media = {
    id: string;
    restaurantId: string;
    title: string;
    type: MediaType;
    url: string;
    source: MediaSource;
    tags: string[];
    createdAt: any;
    updatedAt: any;
}
