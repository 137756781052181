import LoadingSpinner from '@/components/loading-spinner';
import { ModelList } from '@/components/model-list';
import PageHeader from '@/components/page-header';
import ResizeHandle from '@/components/resize-handle/resize-handle';
import { useSyncImages } from '@/hooks/useImageSync';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { Alert, AlertDescription, AlertTitle, Button, Card } from '@ui/components';
import { AnimatePresence, motion } from 'framer-motion';
import { TriangleAlert } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

export default function MatterportModels() {
  const params = useParams();
  const location = useLocation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  if (!params.restaurantSlug) {
    throw Error('Could not find restaurantSlug url param');
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const syncImagesMutation = useSyncImages();

  const handleSyncImages = async () => {
    if (!restaurantQuery.data) {
      return;
    }
    await syncImagesMutation.mutateAsync(restaurantQuery.data.id);
  };

  useEffect(() => {
    setIsSidebarVisible(!!params.modelId || location.pathname.endsWith('/create'));
  }, [location.pathname, params.modelId]);

  if (restaurantQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (restaurantQuery.isError || !restaurantQuery.data) {
    return <div>Restaurant not found</div>;
  }

  const noActiveDefaultModel = !restaurantQuery.data.layouts?.find(
    (layout) => layout.isDefault && layout.isActive,
  );

  return (
    <PanelGroup direction="horizontal" autoSaveId="models-page">
      <Panel defaultSize={70}>
        <Card className="flex-1 w-full h-full">
          <main className="h-full overflow-y-auto">
            <div className="flex items-center justify-between">
              <PageHeader title="Matterport Models" />
              <div className="flex gap-2 px-5">
                <Button variant="secondary" onClick={handleSyncImages}>
                  {syncImagesMutation.isPending ? <LoadingSpinner /> : 'Sync'}
                </Button>
                <Button asChild>
                  <Link to="create">Create New</Link>
                </Button>
              </div>
            </div>

            {noActiveDefaultModel && (
              <div className="p-4">
                <Alert variant="warning">
                  <TriangleAlert className="w-4 h-4" />
                  <AlertTitle>No Active Default Model</AlertTitle>
                  <AlertDescription>
                    Please make sure you have a default model enabled. Otherwise, a Matterport space may not
                    display to users.
                  </AlertDescription>
                </Alert>
              </div>
            )}

            <ModelList models={restaurantQuery.data.layouts || []} restaurant={restaurantQuery.data} />
          </main>
        </Card>
      </Panel>

      <ResizeHandle />

      <AnimatePresence>
        {isSidebarVisible && (
          <Panel defaultSize={30} minSize={20} maxSize={50}>
            <motion.div
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              transition={{ duration: 0.3 }}
              className='w-full h-full'
            >
              <Card className="w-full h-full">
                {/* Outlet is the drawer that opens when selecting an item from the list */}
                <Outlet />
              </Card>
            </motion.div>
          </Panel>
        )}
      </AnimatePresence>
    </PanelGroup>
  );
}
