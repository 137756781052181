import DatesInput from '@/components/constraint-inputs/dates-input';
import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import BasePolicyCard from '@/components/policies/cards/base-policy-card';
import {
  RequestResponseTimeFormData,
  requestResponseTimeFormSchema,
} from '@/components/policies/cards/schemas';
import {
  getBetweenTimesCondition,
  getDayOfWeekCondition,
  getDisplaysArray,
  getHasItemCondition,
  getInitialBetweenTimes,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  getInitialTitle,
} from '@/helpers/form.helper';
import { APPLIES_ON_OPTIONS, AppliesOnOptionKeys, useAppliesOnHandlers } from '@/hooks/useAppliesOnHandlers';
import { CreatePolicyRequest, DisplayLocation, Policy, PolicyType, UpdatePolicyRequest } from '@repo/types';
import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/components';
import * as React from 'react';

interface RequestResponseTimeCardProps {
  policy: Policy;
  maxWeight: number;
  name?: string;
  desciption?: string;
}

const RequestResponseTimeCard: React.FC<RequestResponseTimeCardProps> = ({ policy, maxWeight }) => {
  const defaultAppliesOn = (policy.condition?.value.dates ?? []).length > 0 ? 'dates' : 'daysOfWeek';

  const { appliesOn, handleAppliesOnChange, handleSelectedDatesChange } =
    useAppliesOnHandlers<RequestResponseTimeFormData>(defaultAppliesOn);

  const formDefaultValues: RequestResponseTimeFormData = {
    title: getInitialTitle(policy, 'Request Response Time'),
    disclaimer: getInitialDisclaimer(policy, ''),
    displayTable: policy.displays.some((display) => display.location === DisplayLocation.TABLE),
    displayEmail: policy.displays.some((display) => display.location === DisplayLocation.EMAIL),
    displayCheckout: policy.displays.some((display) => display.location === DisplayLocation.CHECKOUT),
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
      betweenTimes: getInitialBetweenTimes(policy),
      dates: policy.condition?.value.dates || [],
    },
  };

  const getPolicyCreateData = (formData: RequestResponseTimeFormData): CreatePolicyRequest => ({
    name: PolicyType.REQUEST_RESPONSE_TIME,
    description: PolicyType.REQUEST_RESPONSE_TIME,
    type: PolicyType.REQUEST_RESPONSE_TIME,
    displays: getDisplaysArray(formData),
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: RequestResponseTimeFormData): UpdatePolicyRequest => ({
    ...policy,
    id: policy.id,
    displays: getDisplaysArray(formData),
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
    },
  });

  return (
    <BasePolicyCard<RequestResponseTimeFormData>
      formSchema={requestResponseTimeFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={PolicyType.REQUEST_RESPONSE_TIME}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <div className="flex items-center gap-4">
            <Label className="text-nowrap" htmlFor="day-selector-condition">
              Applies on
            </Label>
            <Select
              defaultValue={APPLIES_ON_OPTIONS.daysOfWeek}
              value={appliesOn}
              onValueChange={(value) => {
                handleAppliesOnChange(form, value as AppliesOnOptionKeys);
              }}
            >
              <SelectTrigger id="day-selector-condition">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={'daysOfWeek'}>{APPLIES_ON_OPTIONS.daysOfWeek}</SelectItem>
                <SelectItem value={'dates'}>{APPLIES_ON_OPTIONS.dates}</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {appliesOn === 'dates' && (
            <DatesInput
              form={form}
              name="condition.dates"
              handleSelectedDatesChange={(dates) => handleSelectedDatesChange(form, dates)}
            />
          )}

          {appliesOn === 'daysOfWeek' && <PolicyDayOfWeekSelector form={form} />}

          <div className="flex w-full gap-4">
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.0"
              label="Start"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.1"
              label="End"
              inputProps={{ type: 'time' }}
            />
          </div>

          <RHFTextInput form={form} name="title" label="Title" canCopyToClipboard />
          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" canCopyToClipboard />
          <div className="grid gap-4 sm:grid-cols-3">
            <RHFSwitch
              form={form}
              name="displayTable"
              label="Table"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayCheckout"
              label="Checkout"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayEmail"
              label="Email"
              className="justify-between p-3 border rounded-md"
            />
          </div>
        </>
      )}
    </BasePolicyCard>
  );
};

export default RequestResponseTimeCard;
