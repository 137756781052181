import React, { useState, useRef } from "react";
import { Button, Card, CardContent, CardHeader, Input } from "@ui/components";
import { X } from "lucide-react";

interface FileUploadProps {
    onFilesSelected: (files: File[]) => void; // Callback to send selected files to parent
}

const FileUploader: React.FC<FileUploadProps> = ({ onFilesSelected }) => {
    const [filePreviews, setFilePreviews] = useState<string[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        setSelectedFiles(files);

        const newPreviews = files.map((file) => {
            const reader = new FileReader();
            return new Promise<string>((resolve) => {
                reader.onload = () => resolve(reader.result as string);
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newPreviews).then((previews) => setFilePreviews(previews));
        onFilesSelected(files); // Pass files to the parent
    };

    const clearSelection = () => {
        if (inputRef.current) inputRef.current.value = "";
        setSelectedFiles([]);
        setFilePreviews([]);
        onFilesSelected([]); // Notify parent of cleared files
    };

    return (
        <div className="file-upload">
            <div className="mb-4 cursor-pointer">
                <Input
                    type="file"
                    accept="image/*,video/*"
                    multiple
                    className="cursor-pointer mt-1 block w-full text-sm"
                    onChange={handleFileChange}
                    ref={inputRef}
                />
            </div>

            {filePreviews.length > 0 && (
                <Card className="mb-4">
                    <CardHeader>
                        <h2 className="text-sm font-medium">Preview</h2>
                    </CardHeader>
                    <CardContent className="grid grid-cols-3 gap-2">
                        {filePreviews.map((preview, index) => (
                            <div key={index} className="w-24 h-24 overflow-hidden rounded-md border">
                                <img
                                    src={preview}
                                    alt={`Preview ${index}`}
                                    className="object-cover w-full h-full"
                                />
                            </div>
                        ))}
                    </CardContent>
                </Card>
            )}

            {selectedFiles.length > 0 && (
                <Button
                    variant="outline"
                    className="flex items-center gap-1 mt-2"
                    onClick={clearSelection}
                >
                    <X size={14} /> Clear Selection
                </Button>
            )}
        </div>
    );
};

export default FileUploader;
