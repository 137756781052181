import MediaService, { UploadMediaDTO } from "@/services/media.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useMediaUpload = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["uploadMedia"],
    mutationFn: (data: UploadMediaDTO) => MediaService.uploadEventMediaItem(data),
    onSuccess: (_, { docId: tableId }) => {
      queryClient.invalidateQueries({ queryKey: ["table", tableId] });
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};

export { useMediaUpload };
