import MediaService from "@/services/media.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useMediaDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["deleteMedia"],
    mutationFn: (data: {id: string, restaurantId: string}) => MediaService.deleteMedia(data.id, data.restaurantId),
    onSuccess: (_, { restaurantId }) => {
      queryClient.invalidateQueries({ queryKey: ["media", restaurantId] });
    },
    onError: (error) => {
      console.log({ error });
    },
  });
};

export { useMediaDelete };
