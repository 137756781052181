import { BasePolicyFormData, CutoffFormData, DisplayPolicyFormData } from "@/components/policies/cards/schemas";
import { DEFAULT_POLICY_ICON } from "@/constants/policies.constants";
import {
  CreateCutoff,
  CutoffType,
  CutoffUnit,
  DayOfWeek,
  DayOfWeekFullNameMap,
  DisplayLocation,
  ItemType,
  Policy,
  PolicyWithCutoff,
  UpdateCutoff
} from "@repo/types";

const NUM_DAYS_IN_WEEK = 7;
export const DAYS_OF_WEEK: DayOfWeek[] = ["mon", "tues", "wed", "thurs", "fri", "sat", "sun"];

export const getInitialDaysOfWeek = (policy: Policy | PolicyWithCutoff) => {
  // Default state is all days turned ON
  if (policy.condition === undefined || !("value" in policy.condition) || !policy.condition.value.daysOfWeek) {
    return DAYS_OF_WEEK.reduce(
      (acc, day) => {
        acc[day] = true;
        return acc;
      },
      {} as Record<DayOfWeek, boolean>
    );
  }

  const selectedDays = policy.condition.value.daysOfWeek;
  return DAYS_OF_WEEK.reduce(
    (acc, day) => {
      acc[day] = selectedDays.includes(day);
      return acc;
    },
    {} as Record<DayOfWeek, boolean>
  );
};

export const getInitialBetweenTimes = (policy: Policy | PolicyWithCutoff): [string, string] => {
  if (policy.condition === undefined || !("value" in policy.condition) || !policy.condition.value.betweenTimes) {
    return ["", ""];
  }
  return policy.condition.value.betweenTimes;
};

export const getInitialHasItem = (policy: Policy | PolicyWithCutoff) => {
  if (policy.condition === undefined || !("value" in policy.condition) || !policy.condition.value.hasItem) {
    return {};
  }
  return policy.condition.value.hasItem;
};

export const getInitialTitle = (policy: Policy | PolicyWithCutoff, defaultValue: string): string => {
  if (!policy.displays || policy.displays.length === 0) {
    return defaultValue;
  }

  const firstDisplay = policy.displays[0];
  return firstDisplay.title || defaultValue;
};

export const getInitialDisclaimer = (policy: Policy | PolicyWithCutoff, defaultValue: string): string => {
  if (!policy.displays || policy.displays.length === 0) {
    return defaultValue;
  }

  const firstDisplay = policy.displays[0];
  return firstDisplay.description || defaultValue;
};

export const hasBetweenTimesCondition = (formData: BasePolicyFormData) => {
  return (
    !!formData.condition.betweenTimes?.[0] &&
    !!formData.condition.betweenTimes[1] &&
    formData.condition.betweenTimes?.[0] !== "" &&
    formData.condition.betweenTimes?.[1] !== ""
  );
};

export const hasDayOfWeekConditions = (formData: BasePolicyFormData) => {
  if (!formData.condition.selectedDays) return false;
  return Object.values(formData.condition.selectedDays).filter((isSelected) => isSelected).length !== NUM_DAYS_IN_WEEK;
};

export const getDayOfWeekCondition = (formData: BasePolicyFormData) => {
  if (!formData.condition.selectedDays) return {};
  if (hasDayOfWeekConditions(formData)) {
    return {
      daysOfWeek: isSelectedMapToDaysOfWeekArray(formData.condition.selectedDays),
    };
  }
  return {};
};

export const getBetweenTimesCondition = (formData: BasePolicyFormData) => {
  const betweenTimes = formData.condition.betweenTimes;
  if (betweenTimes?.[0] && betweenTimes[1] && betweenTimes[0] !== "" && betweenTimes[1] !== "") {
    return {
      betweenTimes: [betweenTimes[0], betweenTimes[1]] as [string, string],
    };
  }
  return {};
};

export const getHasItemCondition = (policy: Policy | PolicyWithCutoff) => {
  if (policy.tableId) {
    return {
      hasItem: { type: ItemType.Table, id: policy.tableId },
    };
  }
  return {};
};

export const isSelectedMapToDaysOfWeekArray = (selectedDaysMap: Record<DayOfWeek, boolean>) => {
  return Object.entries(selectedDaysMap)
    .filter(([_, selected]) => selected)
    .map(([day]) => day as DayOfWeek);
};

export const getDisplaysArray = (data: DisplayPolicyFormData) => {
  const display = [];
  if (data.displayTable) {
    display.push({
      title: data.title,
      location: DisplayLocation.TABLE,
      icon: data.icon || DEFAULT_POLICY_ICON,
      description: data.disclaimer,
    });
  }
  if (data.displayEmail) {
    display.push({
      title: data.title,
      location: DisplayLocation.EMAIL,
      icon: data.icon || DEFAULT_POLICY_ICON,
      description: data.disclaimer,
    });
  }
  if (data.displayCheckout) {
    display.push({
      title: data.title,
      location: DisplayLocation.CHECKOUT,
      icon: data.icon || DEFAULT_POLICY_ICON,
      description: data.disclaimer,
    });
  }
  if (data.displayOther) {
    display.push({
      title: data.title,
      location: DisplayLocation.OTHER,
      icon: data.icon || DEFAULT_POLICY_ICON,
      description: data.disclaimer,
    });
  }
  return display;
};

export const getOpenDaysOfWeek = (weekdays: string[]): Record<DayOfWeek, boolean> => {
  return DAYS_OF_WEEK.reduce(
    (acc, day) => {
      acc[day] = !weekdays.includes(DayOfWeekFullNameMap[day]);
      return acc;
    },
    {} as Record<DayOfWeek, boolean>
  );
};

export const isOpenMapToClosedDaysArray = (values: Record<DayOfWeek, boolean>): string[] => {
  const result: string[] = [];

  for (const day in values) {
    if (!values[day as DayOfWeek]) {
      result.push(DayOfWeekFullNameMap[day as DayOfWeek]);
    }
  }

  return result;
};

export const transformCutoff = (data: CutoffFormData, type: CutoffType): CreateCutoff | UpdateCutoff => ({
  type,
  unit: data.unit === CutoffUnit.NONE ? null : data.unit,
  value: data.value === 0 ? null : data.value,
  timeOfDay: data.timeOfDay === '' ? null : data.timeOfDay,
})
