import { useQuery } from '@tanstack/react-query';
import {Media} from "@repo/types";
import MediaService from "@/services/media.service";

const useMedia = (restaurantId: string) => {
    return useQuery<Media[], Error>({
        queryKey: ["media", restaurantId],
        queryFn: () => MediaService.getMedia(restaurantId),
    });
};

export { useMedia };
