import { formatTo12HourTime, ServiceConfig } from '@/helpers/overrides.helper';
import { Alert, Table, TableBody, TableCell, TableHeader, TableRow } from '@ui/components';

export const ServiceConfigPreview = ({ timeRanges }: { timeRanges: ServiceConfig['timeRanges'] }) => {
  return (
    <Alert className="mt-4">
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Time Range</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Is Per Guest</TableCell>
            <TableCell>Available</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Seating Label</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {timeRanges.map((range, index) => (
            <TableRow key={index}>
              <TableCell>
                {`${formatTo12HourTime(range.start)} - ${formatTo12HourTime(range.end)}`}
              </TableCell>
              <TableCell>{range.price}</TableCell>
              <TableCell>{range.isPerGuest ? 'Yes' : 'No'}</TableCell>
              <TableCell>{range.available ? 'Yes' : 'No'}</TableCell>
              <TableCell>{range.resDuration || 'Default'}</TableCell>
              <TableCell>{range.seating || 'None'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Alert>
  );
};
