import { Label } from "@ui/components/ui/label";
import { TimePeriodSelect } from "@ui/components/ui/time-period-select";
import { TimePickerInput } from "@ui/components/ui/time-picker-input";
import { Period } from "@ui/lib/time-picker-utils";
import * as React from "react";

interface TimePickerDemoProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  includeSeconds?: boolean;
}

export function TimePicker12H({ date, setDate, includeSeconds = false }: TimePickerDemoProps) {
  const [period, setPeriod] = React.useState<Period>(date && date.getHours() >= 12 ? "PM" : "AM");

  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);
  const periodRef = React.useRef<HTMLButtonElement>(null);

  return (
    <div className='flex items-end gap-2'>
      <div className='grid gap-2 text-center'>
        <TimePickerInput
          picker='12hours'
          period={period}
          date={date}
          setDate={setDate}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
        <Label htmlFor='hours' className='text-xs'>
          Hours
        </Label>
      </div>
      <div className='grid gap-2 text-center'>
        <TimePickerInput
          picker='minutes'
          id='minutes12'
          date={date}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => (includeSeconds ? secondRef.current?.focus() : periodRef.current?.focus())}
        />
        <Label htmlFor='minutes' className='text-xs'>
          Minutes
        </Label>
      </div>
      {includeSeconds && (
        <div className='grid gap-2 text-center'>
          <Label htmlFor='seconds' className='text-xs'>
            Seconds
          </Label>
          <TimePickerInput
            picker='seconds'
            id='seconds12'
            date={date}
            setDate={setDate}
            ref={secondRef}
            onLeftFocus={() => minuteRef.current?.focus()}
            onRightFocus={() => periodRef.current?.focus()}
          />
        </div>
      )}
      <div className='grid gap-2 text-center'>
        <TimePeriodSelect
          period={period}
          setPeriod={setPeriod}
          date={date}
          setDate={setDate}
          ref={periodRef}
          onLeftFocus={() => (includeSeconds ? secondRef.current?.focus() : minuteRef.current?.focus())}
        />
        <Label htmlFor='period' className='text-xs'>
          Period
        </Label>
      </div>
    </div>
  );
}
