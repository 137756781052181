import { ServiceConfigPreview } from '@/components/service-config-form/service-config-preview';
import { TimeRangeConfig } from '@/components/service-config-form/time-range-config';
import WithTooltip from '@/components/with-tooltip';
import {
  convertToDate,
  formatTo12HourTime,
  ServiceConfig,
  ServicesConfig,
  validateServiceTimeRange,
} from '@/helpers/overrides.helper';
import { ServiceTimeErrors, TimeRangeErrors } from '@/pages/overrides/edit';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TimePicker12H,
} from '@ui/components';
import { addMinutes, isBefore } from 'date-fns';
import { ChevronDown, ChevronsDownUp, ChevronsUpDown, ChevronUp, Pencil, Plus, Trash2 } from 'lucide-react';
import { useState } from 'react';

const timeIntervals = [15, 30, 45, 60].map(String);

type ServiceConfigFormProps = {
  services: ServicesConfig;
  setServices: React.Dispatch<React.SetStateAction<ServicesConfig>>;
  serviceTimeErrors: ServiceTimeErrors;
  setServiceTimeErrors: React.Dispatch<React.SetStateAction<ServiceTimeErrors>>;
  setTimeRangeErrors: React.Dispatch<React.SetStateAction<TimeRangeErrors>>;
};

export default function ServiceConfigForm({
  services,
  setServices,
  serviceTimeErrors,
  setServiceTimeErrors,
  setTimeRangeErrors,
}: ServiceConfigFormProps) {
  const [expandedSections, setExpandedSections] = useState<{ [index: number]: boolean }>({});
  const [editingNameIndex, setEditingNameIndex] = useState<number | null>(null);

  const handleAddService = () => {
    const newService = {
      name: '',
      startTime: '12:00',
      endTime: '12:00',
      timeStep: 30,
      timeRanges: [],
    };

    setServices((prevServices) => {
      const updatedServices = [...prevServices, newService];
      setExpandedSections((prev) => ({
        ...prev,
        [updatedServices.length - 1]: true,
      }));
      return updatedServices;
    });
  };

  const handleDeleteService = (index: number) => {
    setServices(services.filter((_, i) => i !== index));
    setServiceTimeErrors((errors) => {
      const newErrors = { ...errors };
      delete newErrors[index];
      return newErrors;
    });
    setTimeRangeErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[index];
      return newErrors;
    });
    setExpandedSections((prev) => {
      const newExpandedSections = { ...prev };
      delete newExpandedSections[index];
      return newExpandedSections;
    });
  };

  const toggleCollapse = (index: number) => {
    setExpandedSections((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleStartTimeChange = (newStart: string, index: number) => {
    const updatedServices = [...services];
    updatedServices[index].startTime = newStart;

    if (validateServiceTimeRange(newStart, updatedServices[index].endTime)) {
      setServiceTimeErrors((prevErrors) => ({ ...prevErrors, [index]: null }));
    } else {
      setServiceTimeErrors((prevErrors) => ({
        ...prevErrors,
        [index]: 'Start time must be before end time.',
      }));
    }
    setServices(updatedServices);
  };

  const handleEndTimeChange = (newEnd: string, index: number) => {
    const updatedServices = [...services];
    updatedServices[index].endTime = newEnd;

    if (validateServiceTimeRange(updatedServices[index].startTime, newEnd)) {
      setServiceTimeErrors((prevErrors) => ({ ...prevErrors, [index]: null }));
    } else {
      setServiceTimeErrors((prevErrors) => ({
        ...prevErrors,
        [index]: 'End time must be after start time.',
      }));
    }
    setServices(updatedServices);
  };

  const handleExpandAll = () => {
    setExpandedSections(services.reduce((acc, _, index) => ({ ...acc, [index]: true }), {}));
  };

  const handleCollapseAll = () => {
    setExpandedSections(services.reduce((acc, _, index) => ({ ...acc, [index]: false }), {}));
  };

  const handleNameBlur = () => {
    setEditingNameIndex(null);
  };

  const calculateUnconfiguredTimes = (service: ServiceConfig) => {
    const { startTime, endTime, timeRanges } = service;
    const unconfiguredRanges: Array<{ start: string; end: string }> = [];

    // Create a sorted timeline of all timeslots, marking them as covered or not
    const sortedRanges = [...timeRanges].sort(
      (a, b) => convertToDate(a.start).getTime() - convertToDate(b.start).getTime(),
    );

    let currentStart = convertToDate(startTime);
    const serviceEnd = convertToDate(endTime);

    while (isBefore(currentStart, serviceEnd)) {
      const nextMinute = addMinutes(currentStart, 1);
      const isCovered = sortedRanges.some((range) => {
        const rangeStart = convertToDate(range.start);
        const rangeEnd = addMinutes(convertToDate(range.end), service.timeStep); // Include timeStep buffer
        return isBefore(rangeStart, nextMinute) && !isBefore(rangeEnd, currentStart);
      });

      if (!isCovered) {
        const unconfiguredStart = currentStart;

        // Find the next minute that is covered
        while (isBefore(currentStart, serviceEnd)) {
          const checkMinute = addMinutes(currentStart, 1);
          const stillUncovered = !sortedRanges.some((range) => {
            const rangeStart = convertToDate(range.start);
            const rangeEnd = addMinutes(convertToDate(range.end), service.timeStep);
            return isBefore(rangeStart, checkMinute) && !isBefore(rangeEnd, currentStart);
          });

          if (!stillUncovered) break;
          currentStart = checkMinute;
        }

        unconfiguredRanges.push({
          start: formatTo12HourTime(unconfiguredStart.toTimeString().slice(0, 5)),
          end: formatTo12HourTime(currentStart.toTimeString().slice(0, 5)),
        });
      } else {
        currentStart = nextMinute;
      }
    }

    return unconfiguredRanges;
  };

  return (
    <div className="w-full p-4 space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h3 className="pr-2 text-base">Services</h3>
          <WithTooltip content="Collapse all">
            <Button
              size="icon"
              className="text-sm text-muted-foreground"
              variant="ghost"
              onClick={handleCollapseAll}
            >
              <ChevronsDownUp className="w-4 h-4" />
            </Button>
          </WithTooltip>
          <WithTooltip content="Expand all">
            <Button
              size="icon"
              className="text-sm text-muted-foreground"
              variant="ghost"
              onClick={handleExpandAll}
            >
              <ChevronsUpDown className="w-4 h-4" />
            </Button>
          </WithTooltip>
        </div>
        <Button onClick={handleAddService} className="w-48" variant="secondary">
          <Plus className="w-4 h-4" /> Add Service
        </Button>
      </div>
      {services.map((service, index) => {
        const unconfiguredRanges = calculateUnconfiguredTimes(service);

        return (
          <div key={`${service.name}--${index}`} className="p-4 space-y-4 border rounded-lg shadow-md">
            {unconfiguredRanges.length > 0 && (
              <Alert variant="warning">
                <AlertTitle>Unconfigured Timeslots</AlertTitle>
                <AlertDescription>
                  The following timeslots are not configured with any pricing and will not be saved:
                  <ul className="pl-4 list-disc">
                    {unconfiguredRanges.map((range, idx) => (
                      <li key={idx}>
                        {range.start} - {range.end}
                      </li>
                    ))}
                  </ul>
                </AlertDescription>
              </Alert>
            )}

            <div className="flex items-center justify-between gap-4">
              {editingNameIndex === index ? (
                <Input
                  autoFocus
                  className="text-base"
                  placeholder="Service Name (e.g., Breakfast)"
                  value={service.name}
                  onChange={(e) => {
                    const updatedServices = [...services];
                    updatedServices[index].name = e.target.value;
                    setServices(updatedServices);
                  }}
                  onBlur={() => handleNameBlur()}
                />
              ) : (
                <div className="flex items-center gap-2">
                  <h4 className="text-lg font-semibold">{service.name || 'Untitled Service'}</h4>
                  <h5 className="text-sm text-muted-foreground">
                    ({formatTo12HourTime(service.startTime)} - {formatTo12HourTime(service.endTime)})
                  </h5>
                  <Button variant="ghost" size="icon" onClick={() => setEditingNameIndex(index)}>
                    <Pencil className="w-4 h-4" />
                  </Button>
                </div>
              )}
              <div className="flex items-center gap-2">
                <Button onClick={() => toggleCollapse(index)} variant="ghost" size="icon">
                  {expandedSections[index] ? (
                    <ChevronUp className="w-4 h-4" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </Button>
                <Button onClick={() => handleDeleteService(index)} variant="destructive" size="icon">
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            </div>

            <ServiceConfigPreview timeRanges={service.timeRanges} />

            {expandedSections[index] && (
              <div className="space-y-4">
                <h4 className="font-semibold">Booking Window</h4>
                <div className="flex flex-wrap gap-4">
                  <div className="flex flex-col gap-2">
                    <Label>First bookable time</Label>
                    <TimePicker12H
                      date={
                        service.startTime !== ''
                          ? new Date(`1970-01-01T${service.startTime}:00`)
                          : new Date(`1970-01-01T12:00`)
                      }
                      setDate={(date) => {
                        if (date) {
                          handleStartTimeChange(date.toTimeString().slice(0, 5), index);
                        }
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label>Last bookable time</Label>
                    <TimePicker12H
                      date={
                        service.endTime !== ''
                          ? new Date(`1970-01-01T${service.endTime}:00`)
                          : new Date(`1970-01-01T12:00`)
                      }
                      setDate={(date) => {
                        if (date) {
                          handleEndTimeChange(date.toTimeString().slice(0, 5), index);
                        }
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label>Interval between timeslots</Label>
                    <Select
                      value={service.timeStep.toString()}
                      onValueChange={(value: string) => {
                        const updatedServices = [...services];
                        updatedServices[index].timeStep = parseInt(value, 10);
                        setServices(updatedServices);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Time step" />
                      </SelectTrigger>
                      <SelectContent>
                        {timeIntervals.map((interval) => (
                          <SelectItem key={interval} value={interval}>
                            {interval} min
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                {serviceTimeErrors[index] && (
                  <p className="text-sm text-red-500">{serviceTimeErrors[index]}</p>
                )}

                <TimeRangeConfig
                  timeRanges={service.timeRanges}
                  serviceStartTime={convertToDate(service.startTime)}
                  serviceEndTime={convertToDate(service.endTime)}
                  onUpdate={(updatedRanges) => {
                    const updatedServices = [...services];
                    updatedServices[index].timeRanges = updatedRanges;
                    setServices(updatedServices);
                  }}
                  onValidationErrors={(errors) => {
                    setTimeRangeErrors((prevErrors) => ({ ...prevErrors, [index]: errors }));
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
