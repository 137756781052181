import Heading from "./typography/heading";

type PageHeaderProps = {
  title: string;
}

export default function PageHeader({ title }: PageHeaderProps) {
  return (
    <div className='flex items-center justify-between w-full px-4 py-6'>
      <Heading>{title}</Heading>
    </div>
  )
}
