import { API_BASE_URL, CLOUD_FUNCTIONS_URL } from '@/helpers/common.helper';
import { router } from '@/routes';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

const adminApiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

const adminApiClientAutoContent = axios.create({
  baseURL: API_BASE_URL,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

const firebaseFunctionsClient = axios.create({
  baseURL: CLOUD_FUNCTIONS_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

const legacyFileUploadClient = axios.create({
  baseURL: CLOUD_FUNCTIONS_URL,
  headers: {
    'Content-Type': 'text/plain;charset=UTF-8',
  },
});

const retry = (failureCount: number, error: Error) => {
  if ('code' in error && error?.code === 'ERR_NETWORK') {
    return failureCount < 3; // Retry up to 3 times for network errors
  }
  return false; // Do not retry for other types of errors
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error && 'response' in error && (error as AxiosError).response?.status === 401) {
        router.navigate('/sign-in');
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry,
    },
    mutations: {
      retry,
    },
  },
});

export { adminApiClient, firebaseFunctionsClient, queryClient, legacyFileUploadClient, adminApiClientAutoContent };
