import {adminApiClient, adminApiClientAutoContent, legacyFileUploadClient} from '@/helpers/api.client';
export interface UploadMediaDTO {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  name: string;
  collection: string;
  docId: string;
}


export interface UpdateMediaDto {
  title?: string;
  tags?: string[];
  id: string;
  restaurantId: string;
}
interface CreateMediaParams {
  restaurantId: string;
  formData: FormData;
}

const createMedia = async ({ formData, restaurantId }: CreateMediaParams) => {
  const response = await adminApiClientAutoContent.post(
      `/restaurants/${restaurantId}/media`,
      formData,
      {
        headers: {
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      }
  );
  return response.data;
};

const updateMedia = async ({ title, tags, restaurantId, id }: UpdateMediaDto) => {
  const response = await adminApiClient.patch(`/restaurants/${restaurantId}/media/${id}`, {
    title,
    tags,
  });
  return response.data;
};

const deleteMedia = async (id: string, restaurantId: string) => {
  const response = await adminApiClient.delete(`/restaurants/${restaurantId}/media/${id}`);
  return response.data;
}
const getMedia = async (restaurantId: string) => {
  const response = await adminApiClient.get(`/restaurants/${restaurantId}/media`);
  return response.data;
}

const uploadEventMediaItem = async ({ data, name, collection, docId }: UploadMediaDTO) => {
  const response = await legacyFileUploadClient.post(`/uploadEventImage/${collection}/${docId}/${name}`, data);
  return response.data;
}

const MediaService = {
  uploadEventMediaItem,

  createMedia,
  updateMedia,
  deleteMedia,
  getMedia,
};

export default MediaService;
