import { DEFAULT_RES_DURATION_MINUTES, ServiceConfig } from '@/helpers/overrides.helper';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { Button, Checkbox, Input, Label, Separator, Switch, TimePicker12H } from '@ui/components';
import { Plus, Trash2 } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

type TimeRangeConfigProps = {
  timeRanges: ServiceConfig['timeRanges'];
  onUpdate: (timeRanges: ServiceConfig['timeRanges']) => void;
  onValidationErrors: (errors: { [index: number]: string | null }) => void;
  serviceStartTime: Date;
  serviceEndTime: Date;
};

export const TimeRangeConfig = ({
  timeRanges,
  onUpdate,
  onValidationErrors,
  serviceStartTime,
  serviceEndTime,
}: TimeRangeConfigProps) => {
  const params = useParams();
  const [endDay, setEndDay] = useState<{ [index: number]: string }>({});
  const [validationErrors, setValidationErrors] = useState<{ [index: number]: string | null }>({});
  const prevValidationErrors = useRef(validationErrors);

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug || '');
  const currency = restaurantQuery.data?.currency;

  const createTimeFromHHMM = useCallback((time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }, []);

  const validateTimeRange = useCallback(
    (start: string, end: string, isNextDay: boolean) => {
      const startTime = createTimeFromHHMM(start);
      const endTime = createTimeFromHHMM(end);

      if (isNextDay) {
        endTime.setDate(endTime.getDate() + 1);
      }

      if (startTime < serviceStartTime || endTime > serviceEndTime) {
        return `Time range must be between ${serviceStartTime.toTimeString().slice(0, 5)} and ${serviceEndTime.toTimeString().slice(0, 5)}.`;
      }

      if (startTime > endTime) {
        return 'Start time must be before end time, or span overnight.';
      }

      return null;
    },
    [serviceStartTime, serviceEndTime, createTimeFromHHMM],
  );

  useEffect(() => {
    timeRanges.forEach((range, index) => {
      const error = validateTimeRange(range.start, range.end, endDay[index] === 'Next Day');
      setValidationErrors((prevErrors) => ({ ...prevErrors, [index]: error }));
    });
  }, [serviceStartTime, serviceEndTime, endDay, timeRanges, validateTimeRange]);

  useEffect(() => {
    const isErrorsChanged = Object.keys(validationErrors).some(
      (key) => validationErrors[parseInt(key)] !== prevValidationErrors.current[parseInt(key)],
    );

    if (isErrorsChanged) {
      onValidationErrors(validationErrors);
    }

    prevValidationErrors.current = validationErrors;
  }, [validationErrors, onValidationErrors]);

  const handleAddTimeRange = () => {
    onUpdate([
      ...timeRanges,
      {
        start: serviceStartTime.toTimeString().slice(0, 5),
        end: serviceEndTime.toTimeString().slice(0, 5),
        price: 0,
        seating: '',
        isPerGuest: false,
        available: true,
        resDuration: DEFAULT_RES_DURATION_MINUTES,
      },
    ]);
  };

  const handleDeleteTimeRange = (index: number) => {
    onUpdate(timeRanges.filter((_, i) => i !== index));
    setValidationErrors((errors) => {
      const newErrors = { ...errors };
      delete newErrors[index];
      return newErrors;
    });
    setEndDay((prev) => {
      const newEndDay = { ...prev };
      delete newEndDay[index];
      return newEndDay;
    });
  };

  const handleStartTimeChange = (newStart: string, index: number) => {
    const updatedRanges = [...timeRanges];
    updatedRanges[index].start = newStart;

    const error = validateTimeRange(newStart, updatedRanges[index].end, endDay[index] === 'Next Day');
    setValidationErrors((prevErrors) => ({ ...prevErrors, [index]: error }));
    onUpdate(updatedRanges);
  };

  const handleEndTimeChange = (newEnd: string, index: number) => {
    const updatedRanges = [...timeRanges];
    updatedRanges[index].end = newEnd;

    const error = validateTimeRange(updatedRanges[index].start, newEnd, endDay[index] === 'Next Day');
    setValidationErrors((prevErrors) => ({ ...prevErrors, [index]: error }));
    onUpdate(updatedRanges);
  };

  const handleIsPerGuestChange = (index: number, isChecked: boolean) => {
    const updatedRanges = [...timeRanges];
    updatedRanges[index].isPerGuest = isChecked;
    onUpdate(updatedRanges);
  };

  const handleIsAvailableChange = (index: number, isChecked: boolean) => {
    const updatedRanges = [...timeRanges];
    updatedRanges[index].available = isChecked;
    onUpdate(updatedRanges);
  }

  return (
    <div className="mt-4 space-y-4">
      <Separator />
      <h4 className="font-semibold">Pricing Tiers</h4>
      {timeRanges.map((range, index) => (
        <div key={index} className="flex flex-col gap-4">
          <div className="flex flex-wrap justify-start gap-4">
            <div className="flex flex-col gap-2">
              <Label>From</Label>
              <TimePicker12H
                date={range.start ? createTimeFromHHMM(range.start) : serviceStartTime}
                setDate={(date) => {
                  if (date) {
                    handleStartTimeChange(date.toTimeString().slice(0, 5), index);
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>To</Label>
              <TimePicker12H
                date={range.end ? createTimeFromHHMM(range.end) : serviceEndTime}
                setDate={(date) => {
                  if (date) {
                    handleEndTimeChange(date.toTimeString().slice(0, 5), index);
                  }
                }}
              />
            </div>
            <div className="flex gap-4">
              <div className="flex flex-col gap-2">
                <Label>Price ({currency?.toUpperCase()})</Label>
                <Input
                  type="number"
                  className="w-20"
                  value={range.price}
                  onChange={(e) => {
                    const updatedRanges = [...timeRanges];
                    updatedRanges[index].price = parseFloat(e.target.value);
                    onUpdate(updatedRanges);
                  }}
                />
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  id={`per-guest-${index}`}
                  checked={range.isPerGuest || false}
                  onCheckedChange={(isChecked) => handleIsPerGuestChange(index, isChecked as boolean)}
                />
                <Label htmlFor={`per-guest-${index}`}>Per Guest</Label>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id={`is-available-${index}`}
                  checked={range.available || false}
                  onCheckedChange={(isChecked) => handleIsAvailableChange(index, isChecked as boolean)}
                />
                <Label htmlFor={`is-available-${index}`}>Is Available</Label>
              </div>
              <div className="flex items-center justify-end flex-1">
                <Button onClick={() => handleDeleteTimeRange(index)} variant="destructive">
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            </div>
          </div>

          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <Label>Seating Label</Label>
              <Input
                placeholder="(e.g., First Seating)"
                value={range.seating || ''}
                onChange={(e) => {
                  const updatedRanges = [...timeRanges];
                  updatedRanges[index].seating = e.target.value;
                  onUpdate(updatedRanges);
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Reservation Duration (min)</Label>
              <Input
                type="number"
                value={range.resDuration || DEFAULT_RES_DURATION_MINUTES}
                onChange={(e) => {
                  const updatedRanges = [...timeRanges];
                  updatedRanges[index].resDuration = parseInt(e.target.value, 10);
                  onUpdate(updatedRanges);
                }}
              />
            </div>
          </div>

          {validationErrors[index] && <p className="text-sm text-red-500">{validationErrors[index]}</p>}

          <Separator />
        </div>
      ))}
      <Button onClick={handleAddTimeRange} variant="secondary">
        <Plus className="w-4 h-4" /> Add Pricing Tier
      </Button>
    </div>
  );
};
