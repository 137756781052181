export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || '';
export const CLOUD_FUNCTIONS_URL = import.meta.env.VITE_FIREBASE_FUNCTIONS_URL || '';

export const capitalized = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const getFileNameFromUrl = (url: string) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};

export function isEnumMember<E>(value: unknown, enumArg: Record<string | number | symbol, E>): value is E {
  return (Object.values(enumArg) as unknown[]).includes(value);
}
