import { Combobox } from '@/components/combobox';
import LoadingSpinner from '@/components/loading-spinner';
import { useRestaurants } from '@/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function RestaurantSelector({ isExpanded = true }: { isExpanded?: boolean }) {
  const { data: restaurants, isLoading } = useRestaurants();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const currentRestaurantSlug = params.restaurantSlug ?? '';

  const handleRestaurantChange = (newRestaurantSlug: string) => {
    const removeParam = (path: string, param: string | undefined) => {
      if (param) {
        // Remove the parameter and any trailing parameters
        const regex = new RegExp(`${param}(/.*)?`);
        return path.replace(regex, '');
      }
      return path;
    };

    const pathMappings: { [key: string]: string | undefined } = {
      'matterport-pins': params.pinId,
      'matterport-models': params.modelId,
      'special-events': params.eventId,
      'overrides': params.overrideId
    };

    let newPath = location.pathname.replace(currentRestaurantSlug, newRestaurantSlug);

    for (const param of Object.values(pathMappings)) {
      newPath = removeParam(newPath, param);
    }

    navigate(newPath);
  };

  return isLoading || !restaurants ? (
    <LoadingSpinner />
  ) : (
    <Combobox
      isExpanded={isExpanded}
      onValueChange={handleRestaurantChange}
      value={currentRestaurantSlug}
      label="Select restaurant..."
      options={restaurants.map((restaurant) => ({
        label: restaurant.name,
        value: restaurant.slug,
      }))}
    />
  );
}
