import { ConfirmationDialog } from '@/components/confirmation-dialog';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { DataTableDraggable } from '@/components/data-table-draggable';
import { DataTableRowActions } from '@/components/data-table-row-actions';
import LoadingSpinner from '@/components/loading-spinner';
import PageHeader from '@/components/page-header';
import ResizeHandle from '@/components/resize-handle/resize-handle';
import { createNewEmptyOverride } from '@/helpers/overrides.helper';
import { useOverrides } from '@/hooks/useOverrides';
import { useOverridesUpdate } from '@/hooks/useOverridesUpdate';
import { Override } from '@repo/types';
import { ColumnDef, Row, Table } from '@tanstack/react-table';
import { Button, Card, RowDragHandleCell } from '@ui/components';
import { AnimatePresence, motion } from 'framer-motion';
import { Layers2, Pencil, Trash2 } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const handleRowClick = <TData,>(row: Row<TData>, table: Table<TData>) => {
  if (!row.getIsSelected()) {
    table.getSelectedRowModel().rows.forEach((selectedRow) => {
      if (selectedRow.id !== row.id) {
        selectedRow.toggleSelected(false);
      }
    });
    row.toggleSelected();
  }
};

// Guard to complete data fetching before rendering anything
export default function Overrides() {
  const params = useParams();

  if (!params.restaurantSlug) {
    throw Error('Could not find restaurantSlug url param');
  }

  const overridesQuery = useOverrides(params.restaurantSlug);

  if (overridesQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (overridesQuery.isError || !overridesQuery.data) {
    return <div>no data</div>;
  }

  return (
    <OverridesData
      overrides={overridesQuery.data.overrides}
      restaurantId={overridesQuery.data.restaurantId}
    />
  );
}

type OverridesDataProps = {
  restaurantId: string;
  overrides: Override[];
};

const OverridesData = ({ overrides, restaurantId }: OverridesDataProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [overrideToDelete, setOverrideToDelete] = useState<Override | undefined>();

  if (!params.restaurantSlug) {
    throw Error('Could not find restaurantSlug url param');
  }

  const overridesMutation = useOverridesUpdate(restaurantId);

  useEffect(() => {
    setIsSidebarVisible(!!params.overrideId);
  }, [params.overrideId]);

  const handleSaveOrder = async (orderedOverrides: Override[]) => {
    await overridesMutation.mutateAsync({
      id: restaurantId,
      data: orderedOverrides,
    });
  };

  const handleDeleteClick = (override: Override) => {
    setOverrideToDelete(override);
  };

  const handleCancelDelete = () => {
    setOverrideToDelete(undefined);
  };

  const handleConfirmDelete = async () => {
    if (!overrideToDelete) return;

    const updatedOverrides = overrides.filter(
      (override) => override.id !== overrideToDelete.id,
    );

    // Update the overrides using the mutation
    await overridesMutation.mutateAsync({
      id: restaurantId,
      data: updatedOverrides || [],
    });

    // Reset the delete confirmation state and navigate back to list page
    setOverrideToDelete(undefined);
    navigate(`/${params.restaurantSlug}/overrides`);
  };

  const handleDuplicateOverride =  async (override: Override) => {
    const existingOverrides = overrides;

    // Create the new duplicated override
    const newOverride: Override = {
      ...override,
      id: uuidv4(),
      name: `${override.name} (**COPY**)`,
    };

    // Find the index of the original override
    const originalIndex = existingOverrides.findIndex((o) => o.id === override.id);

    if (originalIndex === -1) {
      console.error('Original override not found in the list.');
      return;
    }

    // Insert the duplicated override right after the original
    const updatedOverrides = [
      ...existingOverrides.slice(0, originalIndex + 1),
      newOverride,
      ...existingOverrides.slice(originalIndex + 1),
    ];

    // Save the updated overrides list
    await overridesMutation.mutateAsync({
      id: restaurantId,
      data: updatedOverrides,
    });

    // Navigate to the new duplicated override
    navigate(newOverride.id);
  }

  const handleCreateOverride = async () => {
    const newOverride = createNewEmptyOverride();
    const existingOverrides = overrides || [];

    await overridesMutation.mutateAsync({
      id: restaurantId,
      data: [...existingOverrides, newOverride],
    });

    navigate(newOverride.id); // redirect to edit override page
  };

  const columns = useMemo<ColumnDef<Override>[]>(
    () => [
      {
        id: 'drag-handle',
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      },
      {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        enableSorting: false,
      },
      {
        id: 'actions',
        cell: ({ row, table }) => (
          <DataTableRowActions
            actions={[
              {
                icon: Layers2,
                onClick: () => {
                  handleDuplicateOverride(row.original);
                },
                tooltip: 'Duplicate',
              },
              {
                icon: Trash2,
                to: row.original.id,
                onClick: () => {
                  handleRowClick(row, table);
                  handleDeleteClick(row.original);
                },
                tooltip: 'Delete',
              },
              {
                icon: Pencil,
                to: row.original.id,
                onClick: () => {
                  handleRowClick(row, table);
                },
                tooltip: 'Edit',
              },
            ]}
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <PanelGroup direction="horizontal" autoSaveId="overrides-page">
        <Panel defaultSize={30} minSize={30} maxSize={100} id="overrides-list" order={1}>
          <Card className="flex-1 w-full h-full">
            <main className="h-full gap-2 overflow-y-auto">
              <div className="flex items-center justify-between pr-5">
                <div className="flex gap-2 px-5">
                  <PageHeader title="Timeslot Configuration (Overrides)" />
                  {(overridesMutation.isPending) && (
                    <div className="flex items-center gap-2">
                      <LoadingSpinner size={14} />
                    </div>
                  )}
                </div>
                <Button variant="secondary" onClick={handleCreateOverride}>
                  Create New Override
                </Button>
              </div>

              <div className="flex flex-col gap-4 px-4">
                <DataTableDraggable
                  selectedRowUrlParam="overrideId" // For setting selected row state
                  columns={columns}
                  data={overrides}
                  onSaveOrder={handleSaveOrder}
                />
              </div>
            </main>
          </Card>
        </Panel>

        <ResizeHandle />

        <AnimatePresence>
          {isSidebarVisible && (
            <Panel defaultSize={70} minSize={40} maxSize={70} id={`overrides-sidebar`} order={2}>
              <motion.div
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '100%' }}
                transition={{ duration: 0.3 }}
                className="w-full h-full"
              >
                <Card className="w-full h-full">
                  {/* Outlet is the drawer that opens when selecting an item from the list */}
                  <Outlet />
                </Card>
              </motion.div>
            </Panel>
          )}
        </AnimatePresence>
      </PanelGroup>

      <ConfirmationDialog
        open={!!overrideToDelete}
        onCancel={handleCancelDelete}
        onContinue={handleConfirmDelete}
        title={`Delete Override: ${overrideToDelete?.name}`}
        description="Are you sure you want to delete this override? This action is not reversible."
        continueLabel="Delete"
        cancelLabel="Cancel"
        cancelActionVariant="destructive"
      />
    </>
  );
};
