import { ConfirmationDialog } from '@/components/confirmation-dialog';
import { DataTable } from '@/components/data-table';
import { DataTableColumnHeader } from '@/components/data-table-column-header';
import { ActionItem, DataTableRowActions } from '@/components/data-table-row-actions';
import LoadingSpinner from '@/components/loading-spinner';
import PageHeader from '@/components/page-header';
import ResizeHandle from '@/components/resize-handle/resize-handle';
import { tablzAppBaseUrl } from '@/config/external-link-config';
import { statuses } from '@/config/filter-config';
import { useTables } from '@/hooks';
import { useRestaurant } from '@/hooks/useRestaurant';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useTableUpdate } from '@/hooks/useTableUpdate';
import { Restaurant, Table } from '@repo/types';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Badge, Button, Card, Switch, toast, useToast } from '@ui/components';
import { AnimatePresence, motion } from 'framer-motion';
import { ArchiveRestore, ExternalLink, Settings, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export default function MatterportPins() {
  const params = useParams();
  const location = useLocation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  if (!params.restaurantSlug) {
    throw Error('Could not find restaurantSlug url param');
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const restaurantData = restaurantQuery.data;

  useEffect(() => {
    setIsSidebarVisible(!!params.pinId || location.pathname.endsWith('/create'));
  }, [params.pinId, location.pathname]);

  return restaurantQuery.isLoading || !restaurantData ? null : (
    <PanelGroup direction="horizontal" autoSaveId='pins-page'>
      <Panel defaultSize={70}>
        <Card className="flex-1 w-full h-full">
          <main className="h-full overflow-y-auto">
            <div className="flex items-center justify-between">
              <PageHeader title="Matterport Pins" />
              <div className="flex gap-2 px-5">
                <Button asChild>
                  <Link to="create">Create New</Link>
                </Button>
              </div>
            </div>
            <TablesByRestaurantList restaurantId={restaurantData.id} />
          </main>
        </Card>
      </Panel>

      <ResizeHandle />

      <AnimatePresence>
        {isSidebarVisible && (
          <Panel defaultSize={30} minSize={20} maxSize={50}>
            <motion.div
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              transition={{ duration: 0.3 }}
              className="w-full h-full"
            >
              <Card className="w-full h-full">
                {/* Outlet is the drawer that opens when selecting an item from the list */}
                <Outlet />
              </Card>
            </motion.div>
          </Panel>
        )}
      </AnimatePresence>
    </PanelGroup>
  );
}

interface TablesByRestaurantListProps {
  restaurantId: string;
}
const TablesByRestaurantList = ({ restaurantId }: TablesByRestaurantListProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [includeDeleted, setIncludedDeleted] = useState(false);
  const [selectedTable, setSelectedTable] = useState<Table | null>(null);

  const tablesQuery = useTables({ restaurantId, includeDeleted });
  const restaurantQuery = useRestaurant(restaurantId);

  const updateTable = useTableUpdate();

  const handleDeleteClick = (table: Table) => {
    setSelectedTable(table);
  };

  const handleRestoreClick = async (table: Table) => {
    await updateTable.mutateAsync(
      {
        restaurantId: restaurantId,
        tableId: table.id,
        data: {
          deleted: false,
        },
      },
      {
        onSuccess: () => {
          toast({ title: 'Restored pin', variant: 'success' });
          setSelectedTable(null);
          navigate(`/${restaurantQuery.data?.slug}/matterport-pins`);
        },
        onError: () => {
          toast({ title: 'Failed to restore pin', variant: 'destructive' });
        },
      },
    );
  };

  const handleConfirmDelete = async () => {
    if (!selectedTable) return;

    await updateTable.mutateAsync(
      {
        restaurantId: restaurantId,
        tableId: selectedTable.id,
        data: {
          deleted: true,
        },
      },
      {
        onSuccess: () => {
          toast({ title: 'Deleted pin', variant: 'success' });
          setSelectedTable(null);
          navigate(`/${restaurantQuery.data?.slug}/matterport-pins`);
        },
        onError: () => {
          toast({ title: 'Failed to delete pin', variant: 'destructive' });
        },
      },
    );
  };

  useEffect(() => {
    tablesQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeDeleted]);

  const modelOptions =
    restaurantQuery.data?.layouts
      ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map((layout) => ({
        label: `${layout.displayName} (${layout.model})`,
        value: layout.model,
      })) || [];

  const columns: ColumnDef<Table>[] = [
    {
      accessorKey: 'internalName',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Internal Name" />,
      cell: ({ row }) => (
        <span className="flex items-center gap-2">
          {row.getValue('internalName')}{' '}
          {!!row.original?.clusterSize && row.original.clusterSize > 0 ? (
            <Badge variant="secondary">Cluster</Badge>
          ) : null}
          {row.original.deleted && <Badge variant="destructive">Deleted</Badge>}
        </span>
      ),
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Display Name" />,
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: 'label',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
      cell: ({ row }) => <span className="capitalize">{row.getValue('label')}</span>,
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: 'model',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Model" />,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      cell: ({ row }) => {
        const option = modelOptions.find((option) => option.value === row.getValue('model'));
        return <span>{option ? option.label : ''}</span>;
      },
      enableSorting: true,
      enableHiding: true,
    },
    {
      accessorKey: 'capacity',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Capacity" />,
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const externalLinkAction: ActionItem = {
          icon: ExternalLink,
          to: `${tablzAppBaseUrl}/${restaurantQuery.data?.slug}/${row.original.slug}`,
          external: true,
        };

        const settingsAction: ActionItem = { icon: Settings, to: row.original.id };

        const deleteOrRestoreAction: ActionItem = row.original.deleted
          ? {
              icon: ArchiveRestore,
              to: location.pathname,
              onClick: () => handleRestoreClick(row.original),
              tooltip: 'Restore',
            }
          : {
              icon: Trash2,
              to: location.pathname,
              onClick: () => handleDeleteClick(row.original),
              tooltip: 'Delete',
            };

        const actions: ActionItem[] = [externalLinkAction, deleteOrRestoreAction, settingsAction];

        return <DataTableRowActions actions={actions} />;
      },
    },
    {
      id: 'enabled',
      accessorKey: 'enabled',
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      header: () => null,
      cell: ({ row }) =>
        restaurantQuery.data && (
          <div className="flex items-center justify-center">
            <PinStatusSwitch
              restaurant={restaurantQuery.data}
              pinId={row.original.id}
              isActive={row.getValue('enabled')}
            />
          </div>
        ),
    },
  ];

  if (tablesQuery.isLoading || !tablesQuery.data) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="px-4">
        <DataTable
          columns={columns}
          data={tablesQuery.data}
          searchFilters={[{ placeholder: 'Filter by name...', columnId: 'name' }]}
          facetedFilters={[
            { title: 'Status', columnId: 'enabled', options: statuses },
            { title: 'Model', columnId: 'model', options: modelOptions },
          ]}
          showDeletedRecordsCheckbox={true}
          onShowDeletedRecordsChange={(checked) => setIncludedDeleted(checked)}
        />
      </div>

      <ConfirmationDialog
        open={!!selectedTable}
        title={`Delete '${selectedTable?.name}' pin`}
        description="Are you sure you want to delete this pin?"
        cancelLabel="Cancel"
        continueLabel="Delete"
        onCancel={() => setSelectedTable(null)}
        onContinue={handleConfirmDelete}
      />
    </>
  );
};

const PinStatusSwitch = ({
  restaurant,
  pinId,
  isActive,
}: {
  restaurant: Restaurant;
  pinId: string;
  isActive: boolean;
}) => {
  const [isChecked, setIsChecked] = useState(isActive);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const updateTable = useTableUpdate({
    onMutate: async (variables) => {
      // Optimistically update the UI
      queryClient.setQueryData(['tables', restaurant.id], (old: Table[]) => {
        const updatedTables = old.map((table) =>
          table.id === variables.tableId ? { ...table, disabled: variables.data.disabled } : table,
        );
        return updatedTables;
      });
    },
    onError: (error, variables, _context) => {
      // Rollback to the previous state
      queryClient.setQueryData(['tables', restaurant.id], (old: Table[]) => old);
      // Rollback local state
      setIsChecked(!variables.data.disabled);
      console.error(error);
      toast({
        title: 'Failed to update pin status',
        variant: 'destructive',
      });
    },
    onSettled: () => {
      // Invalidate the query to ensure fresh data
      queryClient.invalidateQueries({ queryKey: ['tables', restaurant.id] });
    },
  });

  const handleCheckedChange = (checked: boolean) => {
    setIsChecked(checked);

    updateTable.mutateAsync({
      restaurantId: restaurant.id,
      tableId: pinId,
      data: {
        disabled: !checked,
      },
    });
  };

  return (
    <Switch checked={isChecked} onCheckedChange={handleCheckedChange} disabled={updateTable.isPending} />
  );
};
