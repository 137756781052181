import { cn } from '@ui/lib/utils';

export default function Heading({
  className,
  onClick,
  children,
}: {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}) {
  return (
    <h1 className={cn('text-xl font-semibold', className)} onClick={onClick}>
      {children}
    </h1>
  );
}
