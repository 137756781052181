import { CutoffUnit, ItemType } from "@repo/types";
import { z } from "zod";

export const baseFormSchema = z.object({
  icon: z.string().optional(),
  condition: z.object({
    selectedDays: z.object({
      mon: z.boolean(),
      tues: z.boolean(),
      wed: z.boolean(),
      thurs: z.boolean(),
      fri: z.boolean(),
      sat: z.boolean(),
      sun: z.boolean(),
    }).optional(),
    dates: z.array(z.string()).optional(),
    betweenTimes: z.tuple([z.string(), z.string()]).optional(),
    hasItem: z
      .object({ type: z.nativeEnum(ItemType), id: z.string() })
      .optional(),
  }),
  disclaimer: z.string().max(500),
});

const cutoffSchema = z
  .object({
    value: z.number().optional(),
    unit: z.nativeEnum(CutoffUnit).optional(),
    timeOfDay: z.string().optional(),
  })
  .refine(
    (data) => {
      const { value, unit } = data;

      return (
        (!value && !unit) ||
        (value && unit && unit !== CutoffUnit.NONE) ||
        (!value && unit === CutoffUnit.NONE)
      );
    },
    (data) => {
      const { value } = data;

      return {
        message: !value
          ? "You must set a value if a unit is selected"
          : "You must choose a unit if a value is set",
        path: [!value ? "value" : "unit"], // This is where the error will be located
      };
    }
  );

export const policyWithDisplayBaseSchema = baseFormSchema.extend({
  title: z.string().optional(),
  icon: z.string().optional(),
  displayTable: z.boolean(),
  displayEmail: z.boolean(),
  displayCheckout: z.boolean(),
  displayOther: z.boolean().optional(),
});

export const bookingGracePeriodFormSchema = policyWithDisplayBaseSchema;
export const requestResponseTimeFormSchema = policyWithDisplayBaseSchema;

export const bookingHorizonFormSchema = baseFormSchema.extend({
  cutoff: cutoffSchema,
  allowRequests: z.boolean(),
});

export const bookingLeadTimeFormSchema = baseFormSchema.extend({
  cutoff: cutoffSchema,
});

export const cutoffCancellationFormSchema = policyWithDisplayBaseSchema.extend({
  cutoff: cutoffSchema,
});

export const cutoffModificationFormSchema = policyWithDisplayBaseSchema.extend({
  cutoff: cutoffSchema,
});

export const selectionFeeFormSchema = policyWithDisplayBaseSchema;

export const depositFeeFormSchema = policyWithDisplayBaseSchema;

export const minimumSpendFormSchema = policyWithDisplayBaseSchema;

export const noShowFeeFormSchema = policyWithDisplayBaseSchema;

export const gratuityFeeFormSchema = policyWithDisplayBaseSchema;

export const customPolicyFormSchema = policyWithDisplayBaseSchema;

// Types
export type BasePolicyFormData = z.infer<typeof baseFormSchema>;
export type DisplayPolicyFormData = z.infer<typeof policyWithDisplayBaseSchema>;
export type CutoffFormData = z.infer<typeof cutoffSchema>;

export type BookingGracePeriodFormData = z.infer<
  typeof bookingGracePeriodFormSchema
>;
export type BookingHorizonFormData = z.infer<typeof bookingHorizonFormSchema>;
export type RequestResponseTimeFormData = z.infer<
  typeof requestResponseTimeFormSchema
>;
export type BookingLeadTimeFormData = z.infer<typeof bookingLeadTimeFormSchema>;
export type CutoffCancellationFormData = z.infer<
  typeof cutoffCancellationFormSchema
>;
export type CutoffModificationFormData = z.infer<
  typeof cutoffModificationFormSchema
>;

export type SelectionFeeFormData = z.infer<typeof selectionFeeFormSchema>;
export type DepositFeeFormData = z.infer<typeof depositFeeFormSchema>;
export type MinimumSpendFormData = z.infer<typeof minimumSpendFormSchema>;
export type NoShowFeeFormData = z.infer<typeof noShowFeeFormSchema>;
export type GratuityFeeFormData = z.infer<typeof gratuityFeeFormSchema>;

export type CustomPolicyFormData = z.infer<typeof customPolicyFormSchema>;
