import { ConfirmationDialog } from '@/components/confirmation-dialog';
import FormWrapper from '@/components/form/form-wrapper';
import LoadingSpinner from '@/components/loading-spinner';
import { BasePolicyFormData } from '@/components/policies/cards/schemas';
import { invalidDisplay } from '@/components/toasts';
import WithTooltip from '@/components/with-tooltip';
import { DEFAULT_POLICY_ICON, PolicyIconMap, PolicyLabelMap } from '@/constants/policies.constants';
import {
  getInitialBetweenTimes,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  getInitialHasItem,
} from '@/helpers/form.helper';
import { useRestaurantPolicyCreate } from '@/hooks/useRestaurantPolicyCreate';
import { useRestaurantPolicyDelete } from '@/hooks/useRestaurantPolicyDelete';
import { useRestaurantPolicyUpdate } from '@/hooks/useRestaurantPolicyUpdate';
import { useTablePolicyCreate } from '@/hooks/useTablePolicyCreate';
import { useTablePolicyDelete } from '@/hooks/useTablePolicyDelete';
import { useTablePolicyUpdate } from '@/hooks/useTablePolicyUpdate';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreatePolicyRequest, Policy, PolicyType, PolicyWithCutoff, UpdatePolicyRequest } from '@repo/types';
import { Button, Card, CardContent, CardFooter, useToast } from '@ui/components';
import { cn } from '@ui/lib/utils';
import { Lock } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { DefaultValues, UseFormReturn, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ZodSchema } from 'zod';

interface BasePolicyCardProps<TFormData extends BasePolicyFormData> {
  children: (formProps: { form: UseFormReturn<TFormData> }) => React.ReactNode;
  formSchema: ZodSchema<TFormData>;
  formDefaultValues: Partial<TFormData>;
  policy: Policy | PolicyWithCutoff;
  policyType: string;
  getPolicyCreateData: (formData: TFormData) => CreatePolicyRequest;
  getPolicyUpdateData: (formData: TFormData) => UpdatePolicyRequest;
}

const BasePolicyCard = <TFormData extends BasePolicyFormData>({
  children,
  formSchema,
  formDefaultValues,
  policy,
  getPolicyCreateData,
  getPolicyUpdateData,
}: BasePolicyCardProps<TFormData>) => {
  const params = useParams();
  const { toast } = useToast();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const { mutateAsync: createRestaurantPolicy } = useRestaurantPolicyCreate(policy.restaurantId);
  const { mutateAsync: updateRestaurantPolicy } = useRestaurantPolicyUpdate(policy.restaurantId);
  const { mutateAsync: deleteRestaurantPolicy, isPending: restaurantDeletePending } =
    useRestaurantPolicyDelete(policy.restaurantId);

  const { mutateAsync: createTablePolicy } = useTablePolicyCreate(policy.tableId);
  const { mutateAsync: updateTablePolicy } = useTablePolicyUpdate(policy.tableId);
  const { mutateAsync: deleteTablePolicy, isPending: tableDeletePending } = useTablePolicyDelete(
    policy.tableId,
  );

  const formValues = {
    icon: PolicyIconMap[policy.type as PolicyType] || DEFAULT_POLICY_ICON,
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
      betweenTimes: getInitialBetweenTimes(policy),
      hasItem: getInitialHasItem(policy),
    },
    disclaimer: getInitialDisclaimer(policy, ''),
    ...formDefaultValues,
  };
  const form = useForm<TFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: formValues as DefaultValues<TFormData>,
    values: formValues as TFormData,
  });
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (values: TFormData) => {
    try {
      if (policy.isPlaceholder) {
        const policyCreateData = getPolicyCreateData(values);
        if (!policyCreateData.displays?.some((display) => display.title)) {
          return toast(invalidDisplay);
        }

        if (policy.tableId) {
          await createTablePolicy({
            restaurantId: policy.restaurantId,
            tableId: policy.tableId,
            data: policyCreateData,
          });
        } else {
          await createRestaurantPolicy({
            id: policy.restaurantId,
            data: policyCreateData,
          });
        }
      } else {
        const policyUpdateData = getPolicyUpdateData(values);
        if (!policyUpdateData.displays?.some((display) => display.title)) {
          return toast(invalidDisplay);
        }

        if (policy.tableId) {
          await updateTablePolicy({
            restaurantId: policy.restaurantId,
            tableId: policy.tableId,
            data: policyUpdateData,
          });
        } else {
          await updateRestaurantPolicy({
            id: policy.restaurantId,
            data: policyUpdateData,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      if (policy.tableId) {
        await deleteTablePolicy({
          policyId: policy.id,
          restaurantId: policy.restaurantId,
          tableId: policy.tableId,
        });
      } else {
        await deleteRestaurantPolicy({
          policyId: policy.id,
          restaurantId: policy.restaurantId,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  isEditable is true if there is no pinId(tableId) in url OR if there is and the policy has a tableId
  const isEditable = !params.pinId || (!!params.pinId && !!policy.tableId);
  const contentClassNames = isEditable ? '' : 'pointer-events-none opacity-35';

  return (
    <FormWrapper<TFormData> form={form} onSubmit={handleSubmit} formRef={formRef}>
      <div className="w-full">
        <Card className="flex flex-col">
          <CardContent className={cn('flex flex-col gap-4 p-4', contentClassNames)}>
            {children({ form })}
          </CardContent>
          <CardFooter className="flex justify-end px-4 pb-4">
            {isEditable ? (
              <div className="flex gap-2">
                {!policy.isPlaceholder && (
                  <Button
                    onClick={() => setIsConfirmDeleteOpen(true)}
                    type="button"
                    disabled={form.formState.isSubmitting || restaurantDeletePending || tableDeletePending}
                    variant="ghostDestructive"
                  >
                    {restaurantDeletePending || tableDeletePending ? <LoadingSpinner /> : 'Delete'}
                  </Button>
                )}
                <Button
                  type="submit"
                  className="w-40"
                  disabled={form.formState.isSubmitting || !form.formState.isDirty}
                >
                  {form.formState.isSubmitting ? (
                    <LoadingSpinner />
                  ) : policy.isPlaceholder ? (
                    'Create'
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            ) : (
              <WithTooltip content="Cannot edit restaurant level policy from a table">
                <div className="flex gap-2">
                  <span className="text-sm">Restaurant level policy</span>
                  <Lock />
                </div>
              </WithTooltip>
            )}
          </CardFooter>
        </Card>
      </div>

      <ConfirmationDialog
        open={isConfirmDeleteOpen}
        title="Confirm deletion"
        description={`Are you sure you want to delete this ${PolicyLabelMap[policy.type as PolicyType] || ''} policy?`}
        cancelLabel="Cancel"
        continueLabel="Delete"
        onContinue={handleDelete}
        onCancel={() => setIsConfirmDeleteOpen(false)}
      />
    </FormWrapper>
  );
};

export default BasePolicyCard;
