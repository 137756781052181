import RHFTextInput from '@/components/form/rhf-text-input';
import LoadingSpinner from '@/components/loading-spinner';
import { useMatterportTags } from '@/hooks/useMatterportTags';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useTable } from '@/hooks/useTable';
import { useTableUpdate } from '@/hooks/useTableUpdate';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Label, ScrollArea } from '@ui/components';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  x: z.number(),
  y: z.number(),
  z: z.number(),
  m_x: z.number(),
  m_y: z.number(),
  m_z: z.number(),
});

export default function Location() {
  const params = useParams();

  if (!params.restaurantSlug) {
    throw Error(`Edit Details page requires a restaurantSlug url param`);
  }

  if (!params.pinId) {
    throw Error(`Edit Details page requires a pinId url param`);
  }
  const updateTableMutation = useTableUpdate();

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const tableQuery = useTable({ restaurantId: restaurantQuery.data!.id, tableId: params.pinId });
  const tableData = tableQuery.data;

  const matterportTagsQuery = useMatterportTags(tableData?.model || '');
  const matterTag = matterportTagsQuery.data?.find(
    (tag: { label: string }) => tag.label === tableData?.name || tag.label === tableData?.internalName,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      x: tableData?.['pos']?.['x'] || 0,
      y: tableData?.['pos']?.['y'] || 0,
      z: tableData?.['pos']?.['z'] || 0,
      m_x: matterTag?.['discPosition']?.x || 0,
      m_y: matterTag?.['discPosition']?.z || 0,
      m_z: (matterTag?.['discPosition']?.y || 0) * -1,
    },
  });

  const handleSync = () => {
    form.setValue('x', matterTag?.['discPosition']?.x || 0);
    form.setValue('y', matterTag?.['discPosition']?.z || 0);
    form.setValue('z', (matterTag?.['discPosition']?.y || 0) * -1);
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await updateTableMutation.mutateAsync({
      restaurantId: restaurantQuery.data!.id,
      tableId: params.pinId!,
      data: { ...tableData, pos: { x: values.x, y: values.y, z: values.z } },
    });
  };

  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <FormProvider {...form}>
        <ScrollArea className="flex-1">
          <form id="location-form" onSubmit={form.handleSubmit(onSubmit)}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '12px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <div className="flex flex-col flex-1 gap-2">
                  <Label htmlFor='model-id'>Model ID</Label>
                  <Input id='model-id' value={tableData?.model} disabled={true} />
                </div>
                <Button
                  type={'button'}
                  variant={'secondary'}
                  style={{ padding: '8px 16px', marginTop: '22px' }}
                  onClick={handleSync}
                >
                  Sync
                </Button>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <RHFTextInput
                  form={form}
                  name={'m_x'}
                  inputProps={{ type: 'number' }}
                  label={'x'}
                  disabled={true}
                />
                <RHFTextInput
                  form={form}
                  name={'x'}
                  inputProps={{ type: 'number' }}
                  label={'x'}
                  disabled={false}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <RHFTextInput
                  form={form}
                  name={'m_y'}
                  label={'y'}
                  inputProps={{ type: 'number' }}
                  disabled={true}
                />
                <RHFTextInput
                  form={form}
                  name={'y'}
                  label={'y'}
                  inputProps={{ type: 'number' }}
                  disabled={false}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <RHFTextInput
                  form={form}
                  name={'m_z'}
                  label={'z'}
                  inputProps={{ type: 'number' }}
                  disabled={true}
                />
                <RHFTextInput
                  form={form}
                  name={'z'}
                  label={'z'}
                  inputProps={{ type: 'number' }}
                  disabled={false}
                />
              </div>
            </div>
          </form>
        </ScrollArea>
      </FormProvider>

      <footer className="p-3 border-t-[1px]">
        <Button
          form="location-form"
          type="submit"
          className="w-full"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
        >
          {form.formState.isSubmitting ? <LoadingSpinner /> : 'Confirm'}
        </Button>
      </footer>
    </div>
  );
}
