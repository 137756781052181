import LoadingSpinner from '@/components/loading-spinner';
import ServiceConfigForm from '@/components/service-config-form/service-config-form';
import Heading from '@/components/typography/heading';
import {
  convertToDefaultTimeSlots,
  convertToServicesConfig,
  ServicesConfig,
  sortServicesByStartTime,
  WEEKDAYS,
} from '@/helpers/overrides.helper';
import { useTables } from '@/hooks';
import { useOverridesUpdate } from '@/hooks/useOverridesUpdate';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  Calendar,
  Card,
  Checkbox,
  DateRange,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Separator,
} from '@ui/components';
import { format, isAfter, parse } from 'date-fns';
import { CalendarIcon, Pencil, TriangleAlert, X } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export type ServiceTimeErrors = { [index: number]: string | null };
export type TimeRangeErrors = {
  [index: number]: { [rangeIndex: number]: string | null };
};

export default function EditOverride() {
  const params = useParams();
  const navigate = useNavigate();

  const [isEditingName, setIsEditingName] = useState(false); // Editing state for override name
  const [overrideName, setOverrideName] = useState(''); // Editable name state
  const [isNameEmpty, setIsNameEmpty] = useState<boolean>();

  const [dateRange, setDateRange] = useState<DateRange>();
  const [specificDates, setSpecificDates] = useState<Date[]>();
  const [selectedDates, setSelectedDates] = useState<Date[]>();
  const [showPastDates, setShowPastDates] = useState(false);
  const [showInternalNames, setShowInternalNames] = useState(true);

  const [services, setServices] = useState<ServicesConfig>([]);
  const [selectedTableIds, setSelectedTableIds] = useState<string[]>([]);
  const [selectedTableTags, setSelectedTableTags] = useState<string[]>([]);
  const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);

  const [serviceTimeErrors, setServiceTimeErrors] = useState<ServiceTimeErrors>({});
  const [timeRangeErrors, setTimeRangeErrors] = useState<TimeRangeErrors>({});

  if (!params.overrideId) {
    throw Error(`EditOverride requires an overrideId url param`);
  }

  if (!params.restaurantSlug) {
    throw Error(`EditOverride requires a restaurantSlug url param`);
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const tablesQuery = useTables({ restaurantId: restaurantQuery.data?.id || '' });
  const overridesMutation = useOverridesUpdate(restaurantQuery.data?.id);
  const overrides = restaurantQuery.data?.overrides;
  const tablesData = tablesQuery.data;
  // const tableTags = uniq(flatMap(tables, table => table.tags || [])).sort();
  const availableTableTags = Array.from(new Set(tablesData?.flatMap((table) => table.tags || []))).sort();

  const currentOverride = useMemo(
    () => overrides?.find((o) => o.id === params.overrideId),
    [overrides, params.overrideId],
  );

  const existingDates = useMemo(() => {
    return currentOverride?.conditions?.dates?.map((dateStr) => parse(dateStr, 'PPP', new Date())) || [];
  }, [currentOverride?.conditions?.dates]);

  useEffect(() => {
    if (!overrideName || overrideName === '') {
      setIsNameEmpty(true);
    } else {
      setIsNameEmpty(false);
    }
  }, [overrideName]);

  useEffect(() => {
    if (currentOverride) {
      setOverrideName(currentOverride.name || '');
    }
  }, [currentOverride]);

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOverrideName(e.target.value);
  };

  // Reset state when overrideId changes
  useEffect(() => {
    setDateRange(undefined);
    setSpecificDates([]);
    setSelectedDates([]);
    setSelectedTableIds(currentOverride?.targetIds || []);
    setSelectedTableTags(currentOverride?.targetTags || []);
    setSelectedWeekdays(currentOverride?.conditions?.weekdays || []);

    if (currentOverride?.values.defaultTimeSlots) {
      const servicesConfig = convertToServicesConfig(currentOverride.values.defaultTimeSlots);
      setServices(sortServicesByStartTime(servicesConfig));
    } else {
      setServices([]);
    }
  }, [params.overrideId, currentOverride]);

  useEffect(() => {
    const getDatesInRange = (startDate: Date, endDate: Date): Date[] => {
      const dates = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    };

    const buildSelectedDates = () => {
      const rangeDates = dateRange?.from && dateRange.to ? getDatesInRange(dateRange.from, dateRange.to) : [];
      const combinedDates = [...existingDates, ...rangeDates, ...(specificDates || [])];

      const uniqueDates = Array.from(new Set(combinedDates.map((date) => date.getTime()))).map(
        (time) => new Date(time),
      );
      uniqueDates.sort((a, b) => a.getTime() - b.getTime());

      setSelectedDates(uniqueDates);
    };

    buildSelectedDates();
  }, [existingDates, dateRange, specificDates]);

  useEffect(() => {
    if (currentOverride?.values.defaultTimeSlots) {
      const servicesConfig = convertToServicesConfig(currentOverride.values.defaultTimeSlots);
      setServices(sortServicesByStartTime(servicesConfig));
    } else {
      setServices([]);
    }
    setSelectedTableIds(currentOverride?.targetIds || []);
    setSelectedTableTags(currentOverride?.targetTags || []);
    setSelectedWeekdays(currentOverride?.conditions?.weekdays || []);
  }, [params.overrideId, currentOverride]);

  const removeDate = (dateToRemove: Date) => {
    setSelectedDates((prevDates) => prevDates?.filter((date) => date.getTime() !== dateToRemove.getTime()));
  };

  const toggleTableSelection = (tableId: string) => {
    setSelectedTableIds((prev) =>
      prev.includes(tableId) ? prev.filter((id) => id !== tableId) : [...prev, tableId],
    );
  };

  const toggleTableTagSelection = (tableTag: string) => {
    setSelectedTableTags((prev) =>
      prev.includes(tableTag) ? prev.filter((tag) => tag !== tableTag) : [...prev, tableTag],
    );
  };

  const toggleWeekdaySelection = (weekday: string) => {
    setSelectedWeekdays((prev) =>
      prev.includes(weekday) ? prev.filter((day) => day !== weekday) : [...prev, weekday],
    );
  };

  const handleSaveOverrides = async () => {
    if (!overrides || !currentOverride) {
      throw new Error('No overrides to update');
    }

    if (!restaurantQuery?.data?.id) {
      throw new Error('Missing restaurantId for updating overrides');
    }

    const defaultTimeSlots = convertToDefaultTimeSlots(services);

    const updatedOverrides = overrides.map((override) => {
      if (override.id === currentOverride.id) {
        return {
          ...override,
          name: overrideName,
          targetIds: selectedTableIds,
          targetTags: selectedTableTags,
          conditions: {
            ...override.conditions,
            weekdays: selectedWeekdays,
            dates: selectedDates?.map((date) => format(date, 'MMMM dd, yyyy')),
          },
          values: {
            ...override.values,
            defaultTimeSlots,
          },
        };
      }
      return override;
    });

    await overridesMutation.mutateAsync({ id: restaurantQuery.data?.id, data: updatedOverrides });
  };

  const handleClose = () => {
    navigate(`/${restaurantQuery.data?.slug}/overrides`);
  };

  const hasErrors =
    Object.values(serviceTimeErrors).some((error) => error) ||
    Object.values(timeRangeErrors).some((rangeErrors) =>
      Object.values(rangeErrors).some((rangeError) => rangeError),
    ) ||
    isNameEmpty;

  if (!currentOverride) return null;

  return (
    <Card className="flex flex-col w-full h-full">
      <header className="flex justify-between px-5 py-3 border-b">
        <div className="flex items-center w-full gap-2">
          {isEditingName ? (
            <div className="flex items-center w-full gap-2">
              <Input
                value={overrideName}
                onChange={handleNameInputChange}
                className="flex-1 px-2 py-1 text-lg font-semibold border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                autoFocus
                onBlur={() => setIsEditingName(false)}
              />
            </div>
          ) : (
            <>
              <Heading className="text-lg font-semibold cursor-pointer">{overrideName}</Heading>
              <Button variant="ghost" size="icon" onClick={() => setIsEditingName(true)}>
                <Pencil className="w-4 h-4" />
              </Button>
            </>
          )}
        </div>
        {isEditingName ? null : (
          <button onClick={handleClose}>
            <X />
          </button>
        )}
      </header>
      {isNameEmpty && (
        <Alert variant="warning">
          <TriangleAlert className="w-4 h-4" />
          <AlertTitle>Missing override name</AlertTitle>
        </Alert>
      )}

      <ScrollArea className="flex-1 w-full">
        {/* Tables section */}
        <div className="flex flex-col items-start gap-4 p-4">
          <div className="flex items-center justify-between w-full">
            <h3 className="text-base">Tables: </h3>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="show-internal-names"
                checked={showInternalNames}
                onCheckedChange={(checked) => setShowInternalNames(checked === true)}
              />
              <label htmlFor="show-internal-names" className="text-sm font-medium">
                Show internal names
              </label>
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            {tablesData?.map((table) => (
              <Badge
                key={table.id}
                variant={selectedTableIds.includes(table.id) ? 'default' : 'secondary'}
                className={`flex items-center justify-center px-6 text-sm cursor-pointer h-9 w-fit ${
                  selectedTableIds.includes(table.id) ? 'border-white' : 'text-gray-400'
                }`}
                onClick={() => toggleTableSelection(table.id)}
              >
                {showInternalNames ? table.internalName : table.name}
              </Badge>
            ))}
          </div>
        </div>

        <Separator />

        {/* Table Tags section */}
        {availableTableTags.length > 0 ? (
          <div className="flex flex-col items-start gap-4 p-4">
            <div className="flex items-center justify-between w-full">
              <h3 className="text-base">Table Tags: </h3>
            </div>

            <div className="flex flex-wrap gap-2">
              {availableTableTags.map((tag, index) => (
                <Badge
                  key={`${tag}--${index}`}
                  variant={selectedTableTags.includes(tag) ? 'default' : 'secondary'}
                  className={`flex items-center justify-center px-6 text-sm cursor-pointer h-9 w-fit ${
                    selectedTableTags.includes(tag) ? 'border-white' : 'text-gray-400'
                  }`}
                  onClick={() => toggleTableTagSelection(tag)}
                >
                  {tag}
                </Badge>
              ))}
            </div>
          </div>
        ) : null}

        <Separator />

        {/* Weekdays section */}
        <div className="flex flex-col items-start gap-4 p-4">
          <h3 className="text-base">Weekdays: </h3>
          <div className="flex flex-wrap gap-2">
            {WEEKDAYS.map((day) => (
              <Badge
                key={day}
                variant={selectedWeekdays.includes(day) ? 'default' : 'secondary'}
                className={`flex items-center justify-center px-6 text-sm cursor-pointer h-9 w-fit ${
                  selectedWeekdays.includes(day) ? 'border-white' : 'text-gray-400'
                }`}
                onClick={() => toggleWeekdaySelection(day)}
              >
                {day}
              </Badge>
            ))}
          </div>
        </div>

        <Separator />

        {/* Dates section */}
        <div className="flex flex-col gap-2 p-4">
          <div className="flex items-center justify-between">
            <h3 className="text-base">Dates: </h3>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="show-hidden-dates"
                checked={showPastDates}
                onCheckedChange={(checked) => setShowPastDates(checked === true)}
              />
              <label htmlFor="show-hidden-dates" className="text-sm font-medium">
                Show dates in the past
              </label>
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            {selectedDates
              ?.filter((date) => (showPastDates ? date : isAfter(date, new Date())))
              ?.map((date) => (
                <Badge
                  key={date.toString()}
                  variant="secondary"
                  className="flex items-center justify-between px-6 text-sm border-white cursor-pointer h-9 w-fit"
                >
                  {format(date, 'PPP')}
                  <X
                    className="w-4 h-4 ml-2 text-gray-500 hover:text-red-500"
                    onClick={() => removeDate(date)}
                  />
                </Badge>
              ))}
          </div>

          {/* Date pickers */}
          <div className="flex items-center gap-6">
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" className={'flex gap-2 w-fit justify-start text-left font-normal'}>
                  <CalendarIcon />
                  <span>Add Date Range</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="range"
                  selected={dateRange}
                  onSelect={setDateRange}
                  initialFocus
                  defaultMonth={dateRange?.from || new Date()}
                />
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" className={'w-fit flex gap-2 justify-start text-left font-normal'}>
                  <CalendarIcon />
                  <span>Add Specific Dates</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="multiple"
                  selected={[...(selectedDates || [])]}
                  onSelect={setSpecificDates}
                  initialFocus
                  defaultMonth={specificDates?.[0] || new Date()}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <Separator />

        <ServiceConfigForm
          services={services || []}
          setServices={setServices}
          serviceTimeErrors={serviceTimeErrors}
          setServiceTimeErrors={setServiceTimeErrors}
          setTimeRangeErrors={setTimeRangeErrors}
          key={params.overrideId}
        />
      </ScrollArea>

      <footer className="p-3 border-t-[1px]">
        <Button
          className="w-full"
          onClick={handleSaveOverrides}
          disabled={overridesMutation.isPending || hasErrors}
        >
          {overridesMutation.isPending ? <LoadingSpinner /> : 'Save'}
        </Button>
      </footer>
    </Card>
  );
}
