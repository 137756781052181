import { FormField, FormItem, FormLabel, FormMessage, PhoneInput } from "@ui/components";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { Country } from "react-phone-number-input";

interface RHFPhoneInput<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  label?: string;
  defaultCountry?: Country;
}

export default function RHFPhoneInput<TFieldValues extends FieldValues>({
  form,
  name,
  label = "Phone",
  defaultCountry = "US",
}: RHFPhoneInput<TFieldValues>) {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className='flex items-center gap-2'>{label}</FormLabel>
          <PhoneInput value={field.value} onChange={field.onChange} defaultCountry={defaultCountry} />
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
