import { adminApiClient, firebaseFunctionsClient } from '@/helpers/api.client';
import { MatterportScan, Override, Restaurant } from '@repo/types';

const findAll = async () => {
  const response = await adminApiClient.get<Restaurant[]>('/restaurants');
  return response.data;
};

const findOne = async (id: string) => {
  const response = await adminApiClient.get<Restaurant>(`/restaurants/${id}`);
  return response.data;
};

const findOneBySlug = async (slug: string) => {
  const response = await adminApiClient.get<Restaurant>(`/restaurants/slug/${slug}`);
  return response.data;
};

const update = async (id: string, data: Partial<Restaurant>) => {
  const response = await adminApiClient.patch<Restaurant>(`/restaurants/${id}`, data);
  return response.data;
};

const updateModel = async ({
  restaurantId,
  modelId,
  data,
}: {
  restaurantId: string;
  modelId: string;
  data: Partial<MatterportScan>;
}) => {
  const response = await adminApiClient.patch(`/restaurants/${restaurantId}/models/${modelId}`, data);
  return response.data;
};

const syncImages = async (restaurantID: string) => {
  const response = await firebaseFunctionsClient.post('/syncImages', { restaurantID });
  return response.data;
};

const getOverrides = async (restaurantSlug: string) => {
  const restaurant = await findOneBySlug(restaurantSlug);
  return {
    restaurantId: restaurant.id,
    overrides: restaurant.overrides
  };
}

const updateOverrides = async (id: string, overrides: Override[]) => {
  const response = await adminApiClient.patch<Restaurant>(`/restaurants/${id}`, { overrides });
  return response.data;
}

const RestaurantService = {
  findAll,
  findOne,
  findOneBySlug,
  update,
  syncImages,
  updateModel,
  getOverrides,
  updateOverrides
};

export default RestaurantService;
