import MediaService, { UpdateMediaDto } from "@/services/media.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useMediaUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["createMedia"],
        mutationFn: (data: UpdateMediaDto) => MediaService.updateMedia(data),
        onSuccess: (_, { restaurantId }) => {
            queryClient.invalidateQueries({ queryKey: ["media", restaurantId] });
        },
        onError: (error) => {
            console.log({ error });
        },
    });
};

export { useMediaUpdate };
