import { useEffect } from 'react';
import { ScrollArea, Button, Card } from '@repo/ui';
import LoadingSpinner from '@/components/loading-spinner';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import { Panel } from 'react-resizable-panels';
import { Media } from '@repo/types';

interface UpdateMediaProps {
  selectedMedia: Media;
  isVisible: boolean;
  onClose: () => void;
  onSave: (updatedMedia: Media) => void;
}

type MediaFormInputs = {
  title: string;
  tags: string;
};

export default function UpdateMedia({ selectedMedia, isVisible, onClose, onSave }: UpdateMediaProps) {
  const { control, handleSubmit, reset, formState } = useForm<MediaFormInputs>({
    defaultValues: {
      title: '',
      tags: '',
    },
  });

  useEffect(() => {
    if (selectedMedia) {
      console.log('selectedMedia', selectedMedia);
      reset({
        title: selectedMedia.title,
        tags: selectedMedia.tags?.join(', '),
      });
    }
  }, [selectedMedia, reset]);

  if (!isVisible || !selectedMedia) return null;

  const handleFormSubmit = (data: MediaFormInputs) => {
    onSave({
      ...selectedMedia,
      title: data.title,
      tags: data.tags.split(',').map((tag) => tag.trim()),
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <Panel defaultSize={30} minSize={20} maxSize={50}>
          <motion.div
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '100%' }}
            transition={{ duration: 0.3 }}
            className="w-full h-full"
          >
            <Card className="w-full h-full">
              <header className="flex justify-between px-5 py-3 border-b">
                <h2>Edit Media Details</h2>
                <button onClick={onClose}>
                  <span>X</span>
                </button>
              </header>

              <ScrollArea className="flex-1 p-5">
                <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-4">
                  {/* Editable Fields */}
                  <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                      Title
                    </label>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <input {...field} id="title" type="text" className="border p-2 rounded w-full" />
                      )}
                    />
                  </div>

                  <div>
                    <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                      Tags (comma separated)
                    </label>
                    <Controller
                      name="tags"
                      control={control}
                      render={({ field }) => (
                        <input {...field} id="tags" type="text" className="border p-2 rounded w-full" />
                      )}
                    />
                  </div>

                  {/* View Only Fields */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Type</label>
                    <p className="border p-2 rounded w-full bg-gray">{selectedMedia.type}</p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">URL</label>
                    <p className="border p-2 rounded w-full bg-gray">{selectedMedia.url}</p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Source</label>
                    <p className="border p-2 rounded w-full bg-gray">{selectedMedia.source}</p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Created At</label>
                    <p className="border p-2 rounded w-full bg-gray">{selectedMedia.createdAt}</p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Updated At</label>
                    <p className="border p-2 rounded w-full bg-gray">{selectedMedia.updatedAt}</p>
                  </div>
                </form>
              </ScrollArea>

              <footer className="p-3 border-t-[1px]">
                <Button
                  onClick={handleSubmit(handleFormSubmit)}
                  className="w-full"
                  disabled={formState.isSubmitting || !formState.isDirty}
                >
                  {formState.isSubmitting ? <LoadingSpinner /> : 'Save Changes'}
                </Button>
              </footer>
            </Card>
          </motion.div>
        </Panel>
      )}
    </AnimatePresence>
  );
}
