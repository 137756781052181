import { BasePolicyFormData } from '@/components/policies/cards/schemas';
import { jsDateToFullDate } from '@/helpers/datetime.helper';
import { useState } from 'react';
import { Path, PathValue, UseFormReturn } from 'react-hook-form';

export const APPLIES_ON_OPTIONS = {
  daysOfWeek: 'Day(s)',
  dates: 'Date(s)',
};
export type AppliesOnOptionKeys = keyof typeof APPLIES_ON_OPTIONS | undefined;

export const useAppliesOnHandlers = <T extends BasePolicyFormData>(defaultValue: AppliesOnOptionKeys) => {
  const [appliesOn, setAppliesOn] = useState<AppliesOnOptionKeys>(defaultValue);

  const handleAppliesOnChange = (form: UseFormReturn<T>, value: AppliesOnOptionKeys) => {
    if (value === 'dates') {
      form.setValue('condition.selectedDays' as Path<T>, undefined as PathValue<T, Path<T>>, {
        shouldDirty: true,
      });
    } else if (value === 'daysOfWeek') {
      form.setValue('condition.dates' as Path<T>, undefined as PathValue<T, Path<T>>, { shouldDirty: true });
    }
    setAppliesOn(value);
  };

  const handleSelectedDatesChange = (form: UseFormReturn<T>, dates: Date[] | undefined) => {
    const formattedDates = [...(dates?.map((date) => jsDateToFullDate(date)) || [])];
    const updatedDates = formattedDates.sort();
    form.setValue('condition.dates' as Path<T>, updatedDates as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    form.setValue('condition.selectedDays' as Path<T>, undefined as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
  };

  return {
    appliesOn,
    handleAppliesOnChange,
    handleSelectedDatesChange,
  };
};
