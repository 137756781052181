import IconWithTooltip from "@/components/icon-with-tooltip";
import OrDivider from "@/components/or-divider";
import CustomPolicyCard from "@/components/policies/cards/custom-policy-card";
import { Condition, Policy, PolicyWithCutoff } from "@repo/types";
import { Button } from "@ui/components";
import { ChevronsDownUp, ChevronsUpDown } from "lucide-react";
import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

interface CustomPolicySectionProps {
  name: string;
  description?: string;
  policies: Policy[];
  onAddPolicy: (placeholderPolicy: Policy | PolicyWithCutoff) => void;
  onRemovePolicy: (id: string) => void;
  title: string;
  restaurantId: string;
  tableId?: string;
}

const CustomPolicySection: React.FC<CustomPolicySectionProps> = ({
  name,
  description,
  policies,
  onAddPolicy,
  onRemovePolicy,
  title,
  restaurantId,
  tableId,
}) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [maxWeight, setMaxWeight] = React.useState(0);

  const handleCreateNewPolicyPlaceholder = () => {
    const placeholderPolicy: Policy = {
      id: uuidv4(),
      tableId: tableId,
      restaurantId: restaurantId,
      type: name,
      name: name,
      description: "",
      is_custom: true,
      condition: undefined,
      displays: [],
      isPlaceholder: true,
    };
    onAddPolicy(placeholderPolicy);
  };

  const isCondition = (object: object): object is Condition => {
    return object && "weight" in object;
  };

  useEffect(() => {
    const policiesWithCondition = policies.filter((policy) => policy.condition);
    const max = policiesWithCondition.reduce((acc, policy) => {
      if (policy.condition && isCondition(policy.condition)) {
        return policy.condition.weight > acc ? policy.condition.weight : acc;
      }
      return acc;
    }, 0);
    setMaxWeight(max);
  }, [policies]);

  return (
    <div>
      <div className={`flex justify-between place-items-center mb-4`}>
        <h5 className='flex items-center gap-3 font-medium'>
          {title}
          {description && <IconWithTooltip text={description} />}
        </h5>
        <Button className='w-8 h-8' variant='outline' size='icon' onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ChevronsUpDown className='w-4 h-4' /> : <ChevronsDownUp className='w-4 h-4' />}
        </Button>
      </div>
      {!collapsed && (
        <div className='flex flex-col gap-2'>
          {policies.map((policy, index) => {
            return (
              <div key={`policy-${index}`}>
                {index > 0 && (
                  <div className='flex justify-between'>
                    <OrDivider />
                  </div>
                )}
                <CustomPolicyCard policy={policy} maxWeight={maxWeight} name={name} description={description} />
              </div>
            );
          })}
          {policies[policies.length - 1]?.isPlaceholder ? (
            <Button type='button' variant='secondary' onClick={() => {
              console.log(policies[policies.length - 1])
              onRemovePolicy(policies[policies.length - 1].id)
            }}>
              Cancel
            </Button>
          ) : (
            <Button className='w-full' variant='secondary' onClick={handleCreateNewPolicyPlaceholder}>
              {policies.length === 0 ? `Add ${title} Policy` : `Create ${title} Override`}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomPolicySection;
