import { Box, Clock, LineChart, MapPin, Scroll, Star } from 'lucide-react';

export interface INavLink {
  title: string;
  href: string;
  icon?: JSX.Element;
  disabled?: boolean;
}

export const restaurantNavConfig: (slug: string) => INavLink[] = (slug) => [
  {
    title: 'Account',
    href: `${slug}/account`,
    icon: <Scroll />,
  },
  {
    title: 'Content',
    href: `${slug}/content`,
    icon: <Star />,
  },
  {
    title: 'Models',
    href: `${slug}/matterport-models`,
    icon: <Box />,
  },
  {
    title: 'Pins',
    href: `${slug}/matterport-pins`,
    icon: <MapPin />,
  },
  {
    title: 'Overrides (Timeslots)',
    href: `${slug}/overrides`,
    icon: <Clock />,
  },
  {
    title: 'Events',
    href: `${slug}/special-events`,
    icon: <Star />,
  },
];

export const settingsNavConfig: (slug: string) => INavLink[] = (slug) => [
  {
    title: 'Dashboard',
    href: `${slug}/dashboard`,
    icon: <LineChart />,
    disabled: true,
  },
];
