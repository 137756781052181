import NavLinks from '@/components/nav-links';
import Heading from '@/components/typography/heading';
import { restaurantConfig } from '@/config/restaurant-config';
import { Card, ScrollArea } from '@ui/components';
import { ChevronLeft } from 'lucide-react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export const ConfigureRestaurant = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname;

  const currentTitle = restaurantConfig.find((link) => currentPath.endsWith(link.href))?.title || 'Configure';

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Card className="flex flex-col w-full h-full" id='restaurant-config'>
      <header className="flex justify-between px-5 py-3 border-b">
        {location.pathname.endsWith('account') ? null : (
          <button onClick={handleBack}>
            <ChevronLeft />
          </button>
        )}
        <div className="flex-1 text-center">
          <Heading>{currentTitle}</Heading>
        </div>
      </header>

      {location.pathname.endsWith(`/${params.restaurantSlug}/account`) ? (
        <ScrollArea className="flex-1 p-3">
          <NavLinks links={restaurantConfig} />
        </ScrollArea>
      ) : (
        <Outlet />
      )}
    </Card>
  );
};
