import DatesInput from '@/components/constraint-inputs/dates-input';
import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFSelect from '@/components/form/rhf-select';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import IconWithTooltip from '@/components/icon-with-tooltip';
import { BasePolicyCard } from '@/components/policies/cards';
import {
  CutoffCancellationFormData,
  cutoffCancellationFormSchema,
} from '@/components/policies/cards/schemas';
import { CutoffUnitOptions } from '@/config/dropdown-options/cutoffs';
import { PolicyDefaultDisclaimerMap } from '@/constants/policies.constants';
import {
  getBetweenTimesCondition,
  getDayOfWeekCondition,
  getDisplaysArray,
  getHasItemCondition,
  getInitialBetweenTimes,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  getInitialTitle,
  transformCutoff,
} from '@/helpers/form.helper';
import { APPLIES_ON_OPTIONS, AppliesOnOptionKeys, useAppliesOnHandlers } from '@/hooks/useAppliesOnHandlers';
import {
  CreatePolicyRequest,
  CutoffType,
  CutoffUnit,
  DisplayLocation,
  PolicyType,
  PolicyWithCutoff,
} from '@repo/types';
import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/components';
import * as React from 'react';

interface CutoffCancellationCardProps {
  policy: PolicyWithCutoff;
  maxWeight: number;
}

const CutoffCancellationCard: React.FC<CutoffCancellationCardProps> = ({ policy, maxWeight }) => {
  const defaultAppliesOn = (policy.condition?.value.dates ?? []).length > 0 ? 'dates' : 'daysOfWeek';

  const { appliesOn, handleAppliesOnChange, handleSelectedDatesChange } =
    useAppliesOnHandlers<CutoffCancellationFormData>(defaultAppliesOn);

  const formDefaultValues: CutoffCancellationFormData = {
    title: getInitialTitle(policy, 'Cancellation Cutoff'),
    disclaimer: getInitialDisclaimer(policy, PolicyDefaultDisclaimerMap[policy.type as PolicyType] || ''),
    cutoff: {
      value: policy.cutoff.value || 0,
      unit: policy.cutoff.unit || CutoffUnit.NONE,
      timeOfDay: policy.cutoff.timeOfDay || '',
    },
    displayTable: policy.displays.some((display) => display.location === DisplayLocation.TABLE),
    displayEmail: policy.displays.some((display) => display.location === DisplayLocation.EMAIL),
    displayCheckout: policy.displays.some((display) => display.location === DisplayLocation.CHECKOUT),
    displayOther: policy.displays?.some((display) => display.location === DisplayLocation.OTHER),
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
      betweenTimes: getInitialBetweenTimes(policy),
      dates: policy.condition?.value.dates || [],
    },
  };

  const getPolicyCreateData = (formData: CutoffCancellationFormData): CreatePolicyRequest => ({
    name: PolicyType.CANCELLATION,
    description: PolicyType.CANCELLATION,
    type: PolicyType.CANCELLATION,
    displays: getDisplaysArray(formData),
    cutoff: transformCutoff(formData.cutoff, CutoffType.CANCELLATION),
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: CutoffCancellationFormData) => ({
    ...policy,
    id: policy.id,
    displays: getDisplaysArray(formData),
    cutoff: transformCutoff(formData.cutoff, CutoffType.CANCELLATION),
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
    },
  });

  return (
    <BasePolicyCard<CutoffCancellationFormData>
      formSchema={cutoffCancellationFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={PolicyType.CANCELLATION}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <div className="flex items-center gap-4">
            <Label className="text-nowrap" htmlFor="day-selector-condition">
              Applies on
            </Label>
            <Select
              defaultValue={APPLIES_ON_OPTIONS.daysOfWeek}
              value={appliesOn}
              onValueChange={(value) => {
                handleAppliesOnChange(form, value as AppliesOnOptionKeys);
              }}
            >
              <SelectTrigger id="day-selector-condition">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={'daysOfWeek'}>{APPLIES_ON_OPTIONS.daysOfWeek}</SelectItem>
                <SelectItem value={'dates'}>{APPLIES_ON_OPTIONS.dates}</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {appliesOn === 'dates' && (
            <DatesInput
              form={form}
              name="condition.dates"
              handleSelectedDatesChange={(dates) => handleSelectedDatesChange(form, dates)}
            />
          )}

          {appliesOn === 'daysOfWeek' && <PolicyDayOfWeekSelector form={form} />}

          <div className="flex w-full gap-4">
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.0"
              label="Start"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.1"
              label="End"
              inputProps={{ type: 'time' }}
            />
          </div>

          <div className="grid gap-4 mb-4 sm:grid-cols-3">
            <RHFTextInput
              form={form}
              name="cutoff.timeOfDay"
              label="Time of Day"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              form={form}
              name="cutoff.value"
              label="Value"
              inputProps={{ type: 'number', min: 0 }}
            />
            <RHFSelect form={form} name="cutoff.unit" label="Unit" options={CutoffUnitOptions} />
          </div>

          <RHFTextInput form={form} name="title" label="Title" canCopyToClipboard />
          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" />

          <div className="grid gap-4 sm:grid-cols-4">
            <RHFSwitch
              form={form}
              name="displayTable"
              label="Table"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayCheckout"
              label="Checkout"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayEmail"
              label="Email"
              className="justify-between p-3 border rounded-md"
            />
            <div className="flex items-center gap-1">
              <RHFSwitch
                form={form}
                name="displayOther"
                label="Other"
                className="justify-between p-3 border rounded-md"
              />
              <IconWithTooltip text='Turn on "Other" to allow displaying this disclaimer in app toasts/popups' />
            </div>
          </div>
        </>
      )}
    </BasePolicyCard>
  );
};

export default CutoffCancellationCard;
