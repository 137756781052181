import { ConfigureRestaurant } from '@/components/configure-restaurant/configure-restaurant';
import { CopyToClipboardButton } from '@/components/copy-to-clipboard-button';
import RHFCombobox from '@/components/form/rhf-combobox';
import RHFPhoneInput from '@/components/form/rhf-phone-input';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextInput from '@/components/form/rhf-text-input';
import LoadingSpinner from '@/components/loading-spinner';
import PageHeader from '@/components/page-header';
import ResizeHandle from '@/components/resize-handle/resize-handle';
import { PhoneCountryCodeMap, TablzCountryOptions, TimezoneOptions } from '@/config/dropdown-options';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useRestaurantUpdate } from '@/hooks/useRestaurantUpdate';
import { zodResolver } from '@hookform/resolvers/zod';
import { Country, Timezone } from '@repo/types';
import { Button, Card, Form, FormItem, FormLabel, Input } from '@ui/components';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  is_live: z.boolean().optional(),
  name: z.string().optional(),
  slug: z.string().optional(),
  displayName: z.string().optional(),
  phoneNumber: z
    .string()
    .refine((value) => isValidPhoneNumber(value), {
      message: 'Invalid phone number',
    })
    .or(z.literal(''))
    .optional(),
  contactEmail: z
    .string()
    .optional()
    .refine(
      (value) => {
        return value === undefined || value === '' || z.string().email().safeParse(value).success;
      },
      { message: 'Invalid email' },
    ),
  address: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.nativeEnum(Country).optional(),
  timezone: z.nativeEnum(Timezone).optional(),
  displayAddress: z.string().optional(),
});

export default function RestaurantAccount() {
  const params = useParams();

  if (!params.restaurantSlug) {
    throw Error('Could not find restaurantSlug url param');
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const restaurantData = restaurantQuery.data;

  const restaurantMutation = useRestaurantUpdate(restaurantData?.id);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      is_live: false,
      name: '',
      slug: '',
      displayName: '',
      displayAddress: '',
      phoneNumber: '',
      contactEmail: '',
      address: '',
      city: '',
      region: '',
      postalCode: '',
      country: Country.USA,
      timezone: Timezone.EASTERN,
      // menuUrl: '',
      // directionsUrl: '',
      // accountId: '',
      // stripeRegion: StripeRegion.USA,
      // currency: Currency.USD,
      // taxes: [],
      // payouts: [],
      // model: '',
      // originalBalanceOwed: 0,
      // isBalancePaidBack: false,
      // brightness: 1,
      // contrast: 1,
    },
    values: {
      ...restaurantData,
      // brightness: restaurantData?.brightness || 1,
      // contrast: restaurantData?.contrast || 1,
      // phoneNumber: restaurantData?.phoneNumber || '',
      // contactEmail: restaurantData?.contactEmail || '',
    },
  });

  const watchCountry = form.watch('country');

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    if (!restaurantData?.id) {
      return;
    }

    const response = await restaurantMutation.mutateAsync({ id: restaurantData.id, data });

    form.reset(response);
  };

  return restaurantQuery.isLoading || !restaurantData ? null : (
    <PanelGroup direction="horizontal" autoSaveId="restaurant-account-page">
      <Panel defaultSize={70}>
        <Card className="flex-1 w-full h-full">
          <main className="h-full gap-2 overflow-y-auto">
            <div className="flex items-center justify-between">
              <PageHeader title="Account" />
            </div>

            <Form {...form}>
              <form className="flex flex-col gap-4 px-4" onSubmit={form.handleSubmit(onSubmit)}>
                <div className="flex gap-4">
                  <FormItem className="flex-1">
                    <FormLabel className="flex items-center gap-2">
                      Restaurant ID
                      <CopyToClipboardButton text={restaurantData.id} />
                    </FormLabel>
                    <Input value={restaurantData.id} readOnly />
                  </FormItem>

                  <div className="flex items-end flex-1 gap-2">
                    <RHFTextInput
                      form={form}
                      name="slug"
                      label="Slug/Handle"
                      inputProps={{ readOnly: true }}
                      canCopyToClipboard
                      className="w-[70%]"
                    />
                    <RHFSwitch form={form} label="Active" name="is_live" className="w-[30%]" />
                  </div>
                </div>

                <div className="grid grid-cols-2 grid-rows-5 gap-4">
                  <RHFTextInput form={form} name="name" label="Restaurant Name" />
                  <RHFTextInput form={form} name="displayName" label="Display Name" />
                  <RHFPhoneInput
                    form={form}
                    name="phoneNumber"
                    defaultCountry={watchCountry && PhoneCountryCodeMap[watchCountry]}
                  />
                  <RHFTextInput form={form} name="contactEmail" label="Email" />
                  <RHFTextInput form={form} name="address" label="Street" canCopyToClipboard />
                  <RHFTextInput form={form} name="city" label="City" canCopyToClipboard />
                  <RHFTextInput form={form} name="region" label="State/Province" />
                  <RHFTextInput form={form} name="postalCode" label="Zip/Postal Code" />
                  <RHFCombobox form={form} name="country" label="Country" options={TablzCountryOptions} />
                  <RHFCombobox form={form} name="timezone" label="Timezone" options={TimezoneOptions} />
                </div>

                <RHFTextInput form={form} name="displayAddress" label="Display Address" />

                <Button className="self-end w-52" disabled={restaurantMutation.isPending || !form.formState.isDirty}>
                  {restaurantMutation.isPending ? <LoadingSpinner /> : 'Save'}
                </Button>
              </form>
            </Form>
          </main>
        </Card>
      </Panel>
      <ResizeHandle />

      <Panel defaultSize={30} minSize={20} maxSize={50}>
        <ConfigureRestaurant />
      </Panel>
    </PanelGroup>
  );
}
