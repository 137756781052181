import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTable } from '@/hooks/useTable';
import { useMedia } from '@/hooks/useMedia';
import { useTableUpdate } from '@/hooks/useTableUpdate';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button, ScrollArea } from '@ui/components';
import LoadingSpinner from '@/components/loading-spinner';
import MediaUploads from '@/components/media-uploads';
import MediaUploadCard from '@/components/media-upload-card';
import {MediaType, Table} from '@repo/types';
import Modal from '@/components/modal';

const formSchema = z.object({
    media: z.array(
        z.object({
            type: z.nativeEnum(MediaType),
            path: z.string(),
        })
    ),
});

export default function TableMediaManagement() {
    const params = useParams();
    const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false);
    const [stagedImages, setStagedImages] = useState<string[]>([]);


    const toggleStageImage = (imageId: string) => {
        setStagedImages((prevStaged) => {
            if (prevStaged.includes(imageId)) {
                return prevStaged.filter((id) => id !== imageId);
            } else {
                return [...prevStaged, imageId];
            }
        });
    };

    if (!params.restaurantSlug) {
        throw new Error('Media Management page requires a restaurantSlug URL param');
    }

    if (!params.pinId) {
        throw new Error('Media Management page requires a pinId URL param');
    }

    const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
    const tableQuery = useTable({
        restaurantId: restaurantQuery.data?.id || '',
        tableId: params.pinId || '',
    });
    const mediaQuery = useMedia(restaurantQuery.data?.id || '');

    const tableData = tableQuery.data;

    const updateTableMutation = useTableUpdate();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            media: tableData?.media || [],
        },
    });

    const watchMedia = form.watch('media');

    useEffect(() => {
        if (tableData?.media && !!form) {
            form.reset({ media: tableData.media });
        }
    }, [tableData, !!form]);

    const onSubmit = async (values: Partial<Table> ) => {
        await updateTableMutation.mutateAsync({
            restaurantId: restaurantQuery.data?.id || '',
            tableId: params.pinId || '',
            data: values,
        });
    };

    const handleDelete = (path: string) => {
        const updatedMedia = watchMedia.filter((item) => item.path !== path);
        form.setValue('media', updatedMedia, { shouldDirty: true });
    };

    // Handle adding media from restaurant library
    const handleAddMedia = () => {
        if(!stagedImages.length) return;
        const updatedMedia = [...watchMedia];
        stagedImages.forEach((imageId) => {
            const mediaItem = mediaQuery.data?.find((item) => item.id === imageId);
            if (mediaItem) {
                const newMediaItem = {
                    path: mediaItem.url,
                    type: mediaItem.type,
                };
                updatedMedia.push(newMediaItem);
            }
        });

        form.setValue('media', updatedMedia, { shouldDirty: true });
        setIsMediaSelectorOpen(false);
    }

    return (
        <>
            <FormProvider {...form}>
                <ScrollArea className="flex-1">
                    <form id="table-media-form" onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-4 px-5 py-3">
                            <Button
                                variant="secondary"
                                onClick={() => setIsMediaSelectorOpen(true)}
                                type="button"
                            >
                                Add Media from Library
                            </Button>

                            {!!watchMedia?.length && (
                                <MediaUploads
                                    form={form}
                                    name="media"
                                    initialItems={watchMedia.map((item) => ({
                                        ...item,
                                        id: item.path,
                                        content: (
                                            <MediaUploadCard
                                                path={item.path}
                                                deleteAction={() => handleDelete(item.path)}
                                                variant="full"
                                                isLoading={false}
                                                type={item.type}
                                            />
                                        ),
                                    }))}
                                />
                            )}
                        </div>
                    </form>
                </ScrollArea>
            </FormProvider>

            <footer className="p-3 border-t-[1px]">
                <Button
                    form="table-media-form"
                    type="submit"
                    className="w-full"
                    disabled={!form.formState.isDirty || form.formState.isSubmitting}
                >
                    {form.formState.isSubmitting ? <LoadingSpinner /> : 'Save Changes'}
                </Button>
            </footer>

            {/* Media Selector Modal */}
            <Modal isOpen={isMediaSelectorOpen} onClose={() => setIsMediaSelectorOpen(false)}>
                <div className="p-4">
                    <h2 className="text-lg font-semibold mb-4">Select Media to Add</h2>
                    {mediaQuery.isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="flex flex-wrap gap-4">
                            {mediaQuery.data?.map((mediaItem) => (
                                <div
                                    onClick={() => toggleStageImage(mediaItem.id)}
                                    key={mediaItem.url}
                                    style={{ cursor: 'pointer' }}
                                >
                                <MediaUploadCard
                                    path={mediaItem.url}
                                    deleteAction={() => {}}
                                    isLoading={false}
                                    variant={'simple'}
                                    type={mediaItem.type}
                                    title={mediaItem.title}
                                    selected={stagedImages.includes(mediaItem.id)}
                                />
                                </div>
                            ))}
                        </div>
                    )}
                    <Button onClick={handleAddMedia} disabled={!stagedImages.length} className="mt-4">
                        Add Selected Media
                    </Button>
                </div>
            </Modal>
        </>
    );
}
