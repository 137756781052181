import { INavLink } from '@/config/nav-config';
import { CalendarRange, CalendarX, Coins, DollarSign, Flag, Image, List, Plug } from 'lucide-react';

export const restaurantConfig: INavLink[] = [
  {
    title: 'Media',
    href: 'media',
    icon: <Image />,
    disabled: true
  },
  {
    title: 'Schedule',
    href: 'schedule',
    icon: <CalendarRange />,
    disabled: true
  },
  {
    title: 'Policies',
    href: 'policies',
    icon: <List />,
  },
  {
    title: 'Fees',
    href: 'fees',
    icon: <Coins />,
  },
  {
    title: 'Closures',
    href: 'closures',
    icon: <CalendarX />,
    disabled: true
  },
  {
    title: 'Taxes & Payouts',
    href: 'taxes-payouts',
    icon: <DollarSign />,
    disabled: true
  },
  {
    title: 'Integrations',
    href: 'integrations',
    icon: <Plug />,
    disabled: true
  },
  {
    title: 'Feature Flags',
    href: 'feature-flags',
    icon: <Flag />,
    disabled: true
  },
];
