import DatesInput from '@/components/constraint-inputs/dates-input';
import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFCombobox from '@/components/form/rhf-combobox';
import RHFSwitch from '@/components/form/rhf-switch';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import BasePolicyCard from '@/components/policies/cards/base-policy-card';
import { CustomPolicyFormData, customPolicyFormSchema } from '@/components/policies/cards/schemas';
import { CustomIconOptions, IconsaxIconOptions } from '@/config/dropdown-options/icons';
import { DEFAULT_POLICY_ICON } from '@/constants/policies.constants';
import {
  getBetweenTimesCondition,
  getDayOfWeekCondition,
  getDisplaysArray,
  getHasItemCondition,
  getInitialBetweenTimes,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  getInitialTitle,
} from '@/helpers/form.helper';
import { APPLIES_ON_OPTIONS, AppliesOnOptionKeys, useAppliesOnHandlers } from '@/hooks/useAppliesOnHandlers';
import { CreatePolicyRequest, DisplayLocation, Policy, UpdatePolicyRequest } from '@repo/types';
import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/components';
import { ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';

interface CustomPolicyCardProps {
  policy: Policy;
  name: string;
  description?: string;
  maxWeight: number;
}

const CustomPolicyCard: React.FC<CustomPolicyCardProps> = ({ policy, name, description = '', maxWeight }) => {
  const defaultAppliesOn = (policy.condition?.value.dates ?? []).length > 0 ? 'dates' : 'daysOfWeek';

  const { appliesOn, handleAppliesOnChange, handleSelectedDatesChange } =
    useAppliesOnHandlers<CustomPolicyFormData>(defaultAppliesOn);

  const formDefaultValues: CustomPolicyFormData = {
    title: getInitialTitle(policy, name || 'Custom Policy'),
    disclaimer: getInitialDisclaimer(policy, ''),
    displayTable: policy.displays.some((display) => display.location === DisplayLocation.TABLE),
    displayEmail: policy.displays.some((display) => display.location === DisplayLocation.EMAIL),
    displayCheckout: policy.displays.some((display) => display.location === DisplayLocation.CHECKOUT),
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
      betweenTimes: getInitialBetweenTimes(policy),
      dates: policy.condition?.value.dates || [],
    },
    icon: policy.displays.at(0)?.icon || DEFAULT_POLICY_ICON,
  };

  const getPolicyCreateData = (formData: CustomPolicyFormData): CreatePolicyRequest => ({
    name: name,
    description: description,
    type: name,
    displays: getDisplaysArray(formData),
    is_custom: true,
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: CustomPolicyFormData): UpdatePolicyRequest => ({
    ...policy,
    id: policy.id,
    displays: getDisplaysArray(formData),
    is_custom: true,
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
    },
  });

  return (
    <BasePolicyCard<CustomPolicyFormData>
      formSchema={customPolicyFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={name}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <div className="flex items-center gap-4">
            <Label className="text-nowrap" htmlFor="day-selector-condition">
              Applies on:
            </Label>
            <Select
              defaultValue={APPLIES_ON_OPTIONS.daysOfWeek}
              value={appliesOn}
              onValueChange={(value) => {
                handleAppliesOnChange(form, value as AppliesOnOptionKeys);
              }}
            >
              <SelectTrigger id="day-selector-condition">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={'daysOfWeek'}>{APPLIES_ON_OPTIONS.daysOfWeek}</SelectItem>
                <SelectItem value={'dates'}>{APPLIES_ON_OPTIONS.dates}</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {appliesOn === 'dates' && (
            <DatesInput
              form={form}
              name="condition.dates"
              handleSelectedDatesChange={(dates) => handleSelectedDatesChange(form, dates)}
            />
          )}

          {appliesOn === 'daysOfWeek' && <PolicyDayOfWeekSelector form={form} />}

          <div className="flex w-full gap-4">
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.0"
              label="Start"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.1"
              label="End"
              inputProps={{ type: 'time' }}
            />
          </div>

          <div className="flex gap-4">
            <RHFTextInput form={form} name="title" label="Title" canCopyToClipboard className="flex-1" />
            <RHFCombobox
              form={form}
              name="icon"
              label="Icon"
              options={[
                { label: 'Custom', options: CustomIconOptions },
                { label: 'Iconsax', options: IconsaxIconOptions },
              ]}
              footer={
                <Link
                  to="https://iconsax-react.pages.dev/"
                  target="_blank"
                  className="flex items-center justify-center gap-2 p-2 text-sm text-muted-foreground"
                >
                  <span>View iconsax library</span>
                  <ExternalLink size={14} />
                </Link>
              }
            />
          </div>

          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" canCopyToClipboard />
          <div className="grid gap-4 sm:grid-cols-3">
            <RHFSwitch
              form={form}
              name="displayTable"
              label="Table"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayCheckout"
              label="Checkout"
              className="justify-between p-3 border rounded-md"
            />
            <RHFSwitch
              form={form}
              name="displayEmail"
              label="Email"
              className="justify-between p-3 border rounded-md"
            />
          </div>
        </>
      )}
    </BasePolicyCard>
  );
};

export default CustomPolicyCard;
