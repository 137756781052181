import IconWithTooltip from "@/components/icon-with-tooltip";
import OrDivider from "@/components/or-divider";
import {
  BookingGracePeriodCard,
  BookingHorizonCard,
  BookingLeadTimeCard,
  CutoffCancellationCard,
  CutoffModificationCard,
  DepositFeeCard,
  GratuityFeeCard,
  MinimumSpendCard,
  NoShowFeeCard,
  SelectionFeeCard,
} from "@/components/policies/cards";
import RequestResponseTimeCard from "@/components/policies/cards/request-response-time-card";
import { PolicyDescriptionMap, PolicyLabelMap } from "@/constants/policies.constants";
import { Condition, CutoffType, ExcludeEnum, Policy, PolicyType, PolicyWithCutoff } from "@repo/types";
import { Button } from "@ui/components";
import { ChevronsDownUp, ChevronsUpDown } from "lucide-react";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface PolicySectionProps {
  name?: string;
  description?: string;
  policies: Policy[];
  onAddPolicy: (placeholderPolicy: Policy | PolicyWithCutoff) => void;
  onRemovePolicy: (id: string) => void;
  type: PolicyType;
  restaurantId: string;
  tableId?: string;
}

const cardComponentMap: Record<
  PolicyType,
  React.FC<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    policy: any; // Policy | PolicyWithCutoff, but that makes ts mad
    maxWeight: number;
    className?: string;
    name?: string;
    description?: string;
  }>
> = {
  [PolicyType.BOOKING_LEAD_TIME]: BookingLeadTimeCard,
  [PolicyType.BOOKING_HORIZON]: BookingHorizonCard,
  [PolicyType.GRACE_PERIOD]: BookingGracePeriodCard,
  [PolicyType.MODIFICATION]: CutoffModificationCard,
  [PolicyType.CANCELLATION]: CutoffCancellationCard,
  [PolicyType.REQUEST_RESPONSE_TIME]: RequestResponseTimeCard,
  [PolicyType.SELECTION_FEE]: SelectionFeeCard,
  [PolicyType.DEPOSIT]: DepositFeeCard,
  [PolicyType.MIN_SPEND]: MinimumSpendCard,
  [PolicyType.NO_SHOW]: NoShowFeeCard,
  [PolicyType.GRATUITY]: GratuityFeeCard,
};

const PolicySection: React.FC<PolicySectionProps> = ({
  name,
  description,
  policies,
  onAddPolicy,
  onRemovePolicy,
  type,
  restaurantId,
  tableId = undefined,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [maxWeight, setMaxWeight] = useState(0);

  const handleCreateNewPolicyPlaceholder = () => {
    const basePlaceholderCutoff = {
      id: uuidv4(),
      value: 0,
      unit: undefined,
      timeOfDay: undefined,
      policy: "",
    };

    let placeholderPolicy: Policy | PolicyWithCutoff = {
      id: uuidv4(),
      tableId: tableId,
      restaurantId: restaurantId,
      type: type,
      name: name || type,
      description: "",
      condition: undefined,
      displays: [],
      isPlaceholder: true,
    };

    const policyTypeToCutoffTypeMap: Record<
      ExcludeEnum<
        PolicyType,
        | PolicyType.GRACE_PERIOD
        | PolicyType.SELECTION_FEE
        | PolicyType.DEPOSIT
        | PolicyType.MIN_SPEND
        | PolicyType.NO_SHOW
        | PolicyType.GRATUITY
        | PolicyType.REQUEST_RESPONSE_TIME
      >,
      CutoffType
    > = {
      [PolicyType.BOOKING_LEAD_TIME]: CutoffType.CREATION,
      [PolicyType.BOOKING_HORIZON]: CutoffType.CREATION,
      [PolicyType.MODIFICATION]: CutoffType.CREATION,
      [PolicyType.CANCELLATION]: CutoffType.CREATION,
    };

    switch (type) {
      case PolicyType.BOOKING_LEAD_TIME:
      case PolicyType.BOOKING_HORIZON:
      case PolicyType.MODIFICATION:
      case PolicyType.CANCELLATION:
        placeholderPolicy = {
          ...placeholderPolicy,
          cutoff: {
            ...basePlaceholderCutoff,
            type: policyTypeToCutoffTypeMap[type],
          },
        };
        break;
      default:
        break;
    }
    onAddPolicy(placeholderPolicy);
  };

  const isCondition = (object: object): object is Condition => {
    return object && "weight" in object;
  };

  useEffect(() => {
    const policiesWithCondition = policies.filter((policy) => policy.condition);
    const max = policiesWithCondition.reduce(
      (acc, policy) => {
        if (policy.condition && isCondition(policy.condition)) {
          return policy.condition.weight > acc ? policy.condition.weight : acc;
        }
        return acc;
      },
      tableId ? 1000 : 0
    );
    setMaxWeight(max);
  }, [policies, tableId]);

  return (
    <div>
      <div className={`flex justify-between place-items-center mb-4`}>
        <h5 className='flex items-center gap-3 font-medium'>
          {PolicyLabelMap[type] || name}
          <IconWithTooltip text={PolicyDescriptionMap[type] || description || type} />
        </h5>
        <Button className='w-8 h-8' variant='outline' size='icon' onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ChevronsUpDown className='w-4 h-4' /> : <ChevronsDownUp className='w-4 h-4' />}
        </Button>
      </div>
      {!collapsed && (
        <div className='flex flex-col gap-2'>
          {policies.map((policy, index) => {
            const CardComponent = cardComponentMap[type];
            return (
              <div key={`policy-${index}`}>
                {index > 0 && (
                  <div className='flex justify-between'>
                    <OrDivider />
                  </div>
                )}
                <CardComponent policy={policy} maxWeight={maxWeight} name={name} description={description} />
              </div>
            );
          })}
          {policies[policies.length - 1]?.isPlaceholder ? (
            <Button type='button' variant='secondary' onClick={() => onRemovePolicy(policies[policies.length - 1].id)}>
              Cancel
            </Button>
          ) : (
            <Button type='button' variant='secondary' onClick={handleCreateNewPolicyPlaceholder}>
              {policies.length === 0
                ? `Add ${PolicyLabelMap[type] || name || "New"} Policy`
                : `Create ${PolicyLabelMap[type] || name || "New"} Override`}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PolicySection;
