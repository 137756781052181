import { Override, TimeSlot } from '@repo/types';
import { addMinutes, format, isBefore, isEqual, parse } from 'date-fns';
import { v4 as uuidv4 } from "uuid";

export const DEFAULT_RES_DURATION_MINUTES = 120;
export const DEFAULT_TIME_STEP_MINUTES = 15;
export const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;

export const createNewEmptyOverride = (): Override => ({
  id: uuidv4(),
  name: '',
  description: '',
  conditions: {
    dates: [],
    weekdays: []
  },
  topic: 'Tables',
  targetIds: [],
  values: {
    defaultTimeSlots: {}
  }
});

/** Data structure for UI */
export type ServiceConfig = {
  name: string; // "Breakfast", "Lunch", "Dinner"
  startTime: string; // Example: "08:00" for Breakfast
  endTime: string; // Example: "11:00" for Breakfast
  timeStep: number; // in minutes (e.g., 15, 30)
  timeRanges: Array<{
    start: string; // Start of the time range, e.g., "08:00"
    end: string; // End of the time range, e.g., "09:30"
    price: number; // Price for this range
    isPerGuest?: boolean;
    available?: boolean;
    seating?: string;
    resDuration?: number;
  }>;
};

export type ServicesConfig = ServiceConfig[];

/** Converts the UI data structure to DB data structure */
export const convertToDefaultTimeSlots = (servicesConfig: ServiceConfig[]): { [time: string]: TimeSlot } => {
  const defaultTimeSlots: { [time: string]: TimeSlot } = {};

  servicesConfig.forEach((service) => {
    const { name: serviceName, timeStep, timeRanges } = service;

    // Loop through each timeRange in the service
    timeRanges.forEach((range) => {
      const rangeStart = parse(range.start, 'HH:mm', new Date());
      const rangeEnd = parse(range.end, 'HH:mm', new Date());

      let currentTime = rangeStart;

      while (isBefore(currentTime, addMinutes(rangeEnd, timeStep)) || isEqual(currentTime, rangeEnd)) {
        const formattedTime = format(currentTime, 'HH:mm');

        // Populate the timeslot for the current time
        defaultTimeSlots[formattedTime] = {
          regularPrice: range.price,
          isPerGuest: range.isPerGuest || false,
          timeLabel: formattedTime,
          available: range.available || false,
          resDuration: range.resDuration,
          service: serviceName,
          seating: range.seating || undefined,
        };

        // Move to the next time slot by the time step
        currentTime = addMinutes(currentTime, timeStep);
      }
    });
  });

  return defaultTimeSlots;
};

/** Converts the DB data structure to UI data structure */
export const convertToServicesConfig = (defaultTimeSlots: { [time: string]: TimeSlot }): ServicesConfig => {
  const servicesMap: { [serviceName: string]: { times: string[]; slots: TimeSlot[] } } = {};

  // Step 1: Group timeslots by service name
  Object.entries(defaultTimeSlots).forEach(([time, slot]) => {
    const serviceName = slot.service || 'Unknown Service';

    if (!servicesMap[serviceName]) {
      servicesMap[serviceName] = { times: [], slots: [] };
    }

    servicesMap[serviceName].times.push(time);
    servicesMap[serviceName].slots.push({ ...slot, timeLabel: time });
  });

  // Step 2: Convert grouped data to servicesConfig
  const servicesConfig: ServiceConfig[] = Object.entries(servicesMap).map(
    ([serviceName, { times, slots }]) => {
      // Sort times for the service in ascending order
      const sortedTimes = times.sort(
        (a, b) => parse(a, 'HH:mm', new Date()).getTime() - parse(b, 'HH:mm', new Date()).getTime(),
      );

      // Determine the start time
      const startTime = sortedTimes[0];

      // Determine the time step (assume consistent intervals between times)
      const timeStep =
        sortedTimes.length > 1
          ? (parse(sortedTimes[1], 'HH:mm', new Date()).getTime() -
              parse(sortedTimes[0], 'HH:mm', new Date()).getTime()) /
            (1000 * 60)
          : 30;

      // Calculate the last bookable time (end time) without adding an extra step
      const lastBookableTime = sortedTimes[sortedTimes.length - 1];

      // Step 3: Identify contiguous time ranges with the same price and seating, and resDuration
      const timeRanges: ServiceConfig['timeRanges'] = [];
      let currentRangeStart = sortedTimes[0];
      let currentPrice = slots[0].regularPrice || 0;
      let isPerGuest = slots[0].isPerGuest || false;
      let isAvailable = slots[0].available || false;
      let currentSeating = slots[0].seating || '';
      let currentResDuration = slots[0].resDuration || DEFAULT_RES_DURATION_MINUTES;

      for (let i = 1; i < sortedTimes.length; i++) {
        const currentSlot = slots.find((slot) => slot.timeLabel === sortedTimes[i]);
        const currentTime = sortedTimes[i];

        if (currentSlot) {
          const currentSlotPrice = currentSlot.regularPrice || 0;
          const currentSlotIsPerGuest = currentSlot.isPerGuest || false;
          const currentSlotIsAvailable = currentSlot.available || false;
          const currentSlotSeating = currentSlot.seating || '';
          const currentSlotResDuration = currentSlot.resDuration || DEFAULT_RES_DURATION_MINUTES;

          // Check if any property changes, indicating a new range
          if (
            currentSlotPrice !== currentPrice ||
            currentSlotIsPerGuest !== isPerGuest ||
            currentSlotIsAvailable !== isAvailable ||
            currentSlotSeating !== currentSeating ||
            currentSlotResDuration !== currentResDuration
          ) {
            // Push the current range
            timeRanges.push({
              start: currentRangeStart,
              end: sortedTimes[i - 1],
              price: currentPrice,
              isPerGuest,
              available: isAvailable,
              seating: currentSeating,
              resDuration: currentResDuration
            });

            // Start a new range
            currentRangeStart = currentTime;
            currentPrice = currentSlotPrice;
            isPerGuest = currentSlotIsPerGuest;
            isAvailable = currentSlotIsAvailable;
            currentSeating = currentSlotSeating;
            currentResDuration = currentSlotResDuration;
          }
        }
      }

      // Add the final range
      timeRanges.push({
        start: currentRangeStart,
        end: lastBookableTime,
        price: currentPrice,
        isPerGuest,
        available: isAvailable,
        seating: currentSeating,
        resDuration: currentResDuration
      });

      // Return the service configuration
      return {
        name: serviceName,
        startTime,
        endTime: lastBookableTime, // Use the last bookable time as the end time
        timeStep,
        timeRanges,
      };
    },
  );

  return servicesConfig;
};

export const convertToDate = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};

export const validateServiceTimeRange = (start: string, end: string): boolean => {
  const startDate = convertToDate(start);
  const endDate = convertToDate(end);
  return startDate <= endDate;
};

export const formatTo12HourTime = (timeString: string) => {
  const parsedTime = parse(timeString, 'HH:mm', new Date());
  return format(parsedTime, 'h:mma').toLowerCase(); // Lowercase for "am/pm" format
};

export const sortServicesByStartTime = (servicesList: ServicesConfig) => {
  const result = [...servicesList].sort((a, b) => {
    const startA = convertToDate(a.startTime);
    const startB = convertToDate(b.startTime);
    return startA.getTime() - startB.getTime();
  });
  return result;
};
