import { ConfirmationDialog } from '@/components/confirmation-dialog';
import BetweenTimesInput from '@/components/constraint-inputs/between-times-input';
import DatesInput from '@/components/constraint-inputs/dates-input';
import DaysOfWeekInput, { DayMappingValues } from '@/components/constraint-inputs/days-of-week-input';
import RHFSlider from '@/components/form/rhf-slider';
import RHFTextInput from '@/components/form/rhf-text-input';
import LoadingSpinner from '@/components/loading-spinner';
import Heading from '@/components/typography/heading';
import { jsDateToFullDate } from '@/helpers/datetime.helper';
import { useTables } from '@/hooks';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useRestaurantUpdate } from '@/hooks/useRestaurantUpdate';
import { useTableBatchUpdate } from '@/hooks/useTableBatchUpdate';
import {
  removeNotConditionFromCurrentModel,
  updateDefaultModelCondition,
} from '@/pages/matterport-models/edit/edit-model.helpers';
import { zodResolver } from '@hookform/resolvers/zod';
import { ConditionValue, MatterportScan } from '@repo/types';
import {
  Button,
  Label,
  ScrollArea,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  useToast
} from '@ui/components';
import { X } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

const DayOfWeekSchema = z.enum(['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun']);

// Define the ConditionValue schema
const conditionValueSchema: z.ZodType<ConditionValue> = z.lazy(() =>
  z.object({
    daysOfWeek: z.array(DayOfWeekSchema).optional(),
    dates: z.array(z.string().regex(/^[A-Za-z]+\s\d{2},\s\d{4}$/)).optional(), // Assuming date format is 'MMMM dd, yyyy'
    betweenDates: z.tuple([z.string(), z.string()]).optional(),
    betweenTimes: z.tuple([z.string(), z.string()]).optional(), // Assuming time strings like '16:00', '5:45 pm'
    not: z.lazy(() => conditionValueSchema).optional(), // Recursively define the 'not' field
    and: z.array(conditionValueSchema).optional(),
  }),
);

const formSchema = z.object({
  displayName: z.string(),
  brightness: z.number(),
  contrast: z.number(),
  model: z.string()
    .refine((val) => /^[a-zA-Z0-9]+$/.test(val), {
      message: "Model ID cannot contain special characters",
    }),
  tableStatuses: z.array(
    z.object({
      id: z.string(),
      disabled: z.boolean(),
    }),
  ),
  condition: conditionValueSchema.optional(),
  isDefault: z.boolean().optional(),
});

export type EditModelSettingsForm = z.infer<typeof formSchema>;

const triggerOptions = {
  default: 'Default',
  daysOfWeek: 'Day(s)',
  dates: 'Date(s)',
};
type TriggerOptionsKeys = keyof typeof triggerOptions | undefined;

export default function EditModelSettings() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { toast } = useToast();

  const [triggerValue, setTriggerValue] = useState<TriggerOptionsKeys>();

  if (!params.restaurantSlug) {
    throw Error(`EditModelSettings requires a restaurantSlug url param`);
  }

  if (!params.modelId) {
    throw Error(`EditModelSettings requires a modelId url param`);
  }

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const tablesQuery = useTables({ restaurantId: restaurantQuery.data?.id || '' });

  const updateRestaurantMutation = useRestaurantUpdate(restaurantQuery.data?.id || '');
  const batchUpdateTablesMutation = useTableBatchUpdate();

  const currentModel = restaurantQuery.data?.layouts?.find((layout) => layout.model === params.modelId);
  const existingDefaultModel = restaurantQuery.data?.layouts?.find((layout) => layout.isDefault);
  const tablesOfCurrentModel = tablesQuery.data
    ?.filter((table) => table.model === params.modelId)
    .sort((a, b) => a.internalName.localeCompare(b.internalName));

  const emptyCondition: ConditionValue = {
    dates: undefined,
    daysOfWeek: undefined,
    betweenDates: undefined,
    betweenTimes: undefined,
    not: undefined,
    and: undefined,
  };

  const defaultValues = useMemo(
    () => ({
      displayName: currentModel?.displayName || '',
      model: currentModel?.model || '',
      brightness: currentModel?.brightness || 1,
      contrast: currentModel?.contrast || 1,
      tableStatuses:
        tablesOfCurrentModel?.map((table) => ({
          id: table.id,
          disabled: table.disabled || false,
        })) || [],
      condition: {
        ...emptyCondition,
        ...(currentModel?.condition
          ? {
              ...currentModel.condition,
              // need these sorted to accurately track isDirty state
              daysOfWeek: currentModel.condition.daysOfWeek?.sort(),
              dates: currentModel.condition.dates?.sort(),
            }
          : {}),
      },
      isDefault: currentModel?.isDefault ?? false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentModel, tablesOfCurrentModel],
  );

  const form = useForm<EditModelSettingsForm>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  // Reset form every time the currentModel changes
  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel]);

  // Block navigating elsewhere when form has unsaved changes
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return form.formState.isDirty && currentLocation.pathname !== nextLocation.pathname;
  });

  const handleClose = () => {
    // TODO: check if form state is dirty
    const newPath = location.pathname.replace(`/${params.modelId}`, '');
    navigate(newPath);
  };

  const toggleDisabledStatus = (id: string) => {
    const currentDisabledValues = form.watch('tableStatuses');
    const updatedDisabledValues = currentDisabledValues.map((table) =>
      table.id === id ? { ...table, disabled: !table.disabled } : table,
    );
    form.setValue('tableStatuses', updatedDisabledValues, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<EditModelSettingsForm> = async (values) => {
    const { tableStatuses, ...currentModelUpdatedValues } = values; // Exclude tableStatuses from the currentModelUpdatedValues
    const id = restaurantQuery.data?.id;
    const existingModels = restaurantQuery.data?.layouts || [];

    if (!id || !currentModel) {
      return;
    }

    // Check if the AND betweenTimes are empty strings and set to undefined if true
    if (
      values.condition?.and?.[0].betweenTimes?.[0] === '' &&
      values.condition?.and?.[0].betweenTimes?.[1] === ''
    ) {
      values.condition.and = undefined;
    }

    if (
      triggerValue === 'default' &&
      !!existingDefaultModel &&
      existingDefaultModel.model !== params.modelId
    ) {
      toast({
        title: 'Can only have one default model',
        description: `${existingDefaultModel.displayName} is currently the default`,
        variant: 'destructive',
      });
      return;
    }

    const promises = [];

    // Check if tableStatuses are dirty and add the mutation promise if true
    if (form.formState.dirtyFields.tableStatuses) {
      promises.push(
        batchUpdateTablesMutation.mutateAsync({
          restaurantId: id,
          data: tableStatuses.map((table) => table),
        }),
      );
    }

    // Check if any other fields are dirty
    const otherDirtyFields = Object.keys(form.formState.dirtyFields).filter(
      (field) => field !== 'tableStatuses',
    );
    if (otherDirtyFields.length > 0) {
      let updatedModels = [
        ...existingModels.filter((model) => model.model !== params.modelId),
        {
          ...currentModel,
          ...currentModelUpdatedValues,
        },
      ];

      if (currentModelUpdatedValues.isDefault || updatedModels.some((model) => model.isDefault)) {
        updatedModels = updateDefaultModelCondition(updatedModels);
      } else if (currentModel.isDefault) {
        updatedModels = removeNotConditionFromCurrentModel(updatedModels, currentModel);
      }

      promises.push(
        updateRestaurantMutation.mutateAsync({
          id,
          data: {
            layouts: updatedModels,
          },
        }),
      );
    }

    await Promise.all(promises);

    navigate(`/${restaurantQuery.data?.slug}/matterport-models/${currentModelUpdatedValues.model}`)
  };

  const handleSelectedDaysChange = (day: DayMappingValues, checked: boolean) => {
    const currentDays = form.getValues('condition.daysOfWeek') || [];
    const updatedDays = checked ? [...currentDays, day] : currentDays.filter((d) => d !== day);
    form.setValue('condition.daysOfWeek', updatedDays.sort(), {
      shouldDirty: true,
    });
    form.setValue('condition.dates', undefined, { shouldDirty: true });

    // Check if the currentModel is the default
    if (currentModel?.isDefault) {
      // If any days are selected, set isDefault to false and clear the 'not' condition,
      // If no days are selected set isDefault to true and reset the condition to defaultValue
      if (updatedDays.length > 0) {
        form.setValue('isDefault', false, { shouldDirty: true });
        form.setValue('condition.not', undefined, { shouldDirty: true });
      } else {
        form.setValue('isDefault', true, { shouldDirty: true });
        form.setValue('condition', defaultValues?.condition, { shouldDirty: true });
      }
    }
  };

  const handleSelectedDatesChange = (dates: Date[] | undefined) => {
    const formattedDates = [...(dates?.map((date) => jsDateToFullDate(date)) || [])];
    const updatedDates = formattedDates.length > 0 ? formattedDates.sort() : undefined;
    form.setValue('condition.dates', updatedDates, {
      shouldDirty: true,
    });
    form.setValue('condition.daysOfWeek', undefined, { shouldDirty: true });
    form.setValue('condition.not', undefined, { shouldDirty: true });

    // Check if the currentModel is the default
    if (currentModel?.isDefault) {
      // If any dates are selected, set isDefault to false and clear the 'not' condition,
      // If no dates are selected set isDefault to true and reset the condition to defaultValue
      if (updatedDates && updatedDates.length > 0) {
        form.setValue('isDefault', false, { shouldDirty: true });
        form.setValue('condition.not', undefined, { shouldDirty: true });
      } else {
        form.setValue('isDefault', true, { shouldDirty: true });
        form.setValue('condition', defaultValues?.condition, { shouldDirty: true });
      }
    }
  };

  const getCurrentModelTriggerValue = (currentModel: MatterportScan): TriggerOptionsKeys => {
    let result: TriggerOptionsKeys;
    if (currentModel?.isDefault) {
      result = 'default';
    } else if (currentModel?.condition?.daysOfWeek) {
      result = 'daysOfWeek';
    } else if (currentModel?.condition?.dates || currentModel?.condition?.betweenDates) {
      result = 'dates';
    }

    return result;
  };

  const resetConditionDefaults = () => {
    form.setValue('condition.dates', defaultValues.condition.dates, { shouldDirty: true });
    form.setValue('condition.daysOfWeek', defaultValues.condition.daysOfWeek, { shouldDirty: true });
    form.setValue('condition.betweenDates', defaultValues.condition.betweenDates, { shouldDirty: true });
    form.setValue('condition.betweenTimes', defaultValues.condition.betweenTimes, { shouldDirty: true });
    form.setValue('condition.not', defaultValues.condition.not, { shouldDirty: true });
    form.setValue('condition.and', defaultValues.condition.and, { shouldDirty: true });
  };

  const conditionInputs: { [key: string]: JSX.Element | null } = {
    default: null,
    daysOfWeek: (
      <>
        <DaysOfWeekInput
          form={form}
          name="condition.daysOfWeek"
          handleSelectedDaysChange={handleSelectedDaysChange}
        />

        <BetweenTimesInput form={form} />
      </>
    ),
    dates: (
      <DatesInput form={form} name="condition.dates" handleSelectedDatesChange={handleSelectedDatesChange} />
    ),
  };

  useEffect(() => {
    if (currentModel) {
      setTriggerValue(getCurrentModelTriggerValue(currentModel));
    }
  }, [currentModel]);

  // Update the condition and isDefault values when the triggerValue changes
  useEffect(() => {
    if (!triggerValue || !currentModel) return;

    resetConditionDefaults();

    if (triggerValue === 'default') {
      form.setValue('isDefault', true, { shouldDirty: true });
    } else {
      form.setValue('isDefault', defaultValues.isDefault, { shouldDirty: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue, currentModel]);

  if (restaurantQuery.isLoading || updateRestaurantMutation.isPending) {
    return <LoadingSpinner />;
  }

  if (!currentModel) {
    return <div>Model not found</div>;
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <header className="flex justify-between px-5 py-3 border-b">
          <Heading>{currentModel.displayName}</Heading>
          <button onClick={handleClose}>
            <X />
          </button>
        </header>

        <FormProvider {...form}>
          <ScrollArea className="flex-1">
            <form id="edit-model-form" onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 px-5 py-3">
                <RHFTextInput label="Display Name" form={form} name="displayName" />
                <RHFTextInput label="Model ID" form={form} name="model" />

                {!tablesOfCurrentModel || tablesOfCurrentModel.length === 0 ? null : (
                  <div className="flex flex-col rounded-sm border-[1px]">
                    {tablesOfCurrentModel.map((table) => (
                      <div className="flex items-center h-16 border-b" key={table.id}>
                        <span className="flex-1 p-3">{table.internalName}</span>
                        <div className="flex items-center">
                          <div className="p-3">
                            <Switch
                              checked={
                                form.watch('tableStatuses').find((t) => t.id === table.id)?.disabled === false
                              }
                              onCheckedChange={() => toggleDisabledStatus(table.id)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <Label htmlFor="trigger-condition">Trigger</Label>
                <Select
                  defaultValue={'default'}
                  value={triggerValue}
                  onValueChange={(value) => {
                    setTriggerValue(value as TriggerOptionsKeys);
                  }}
                >
                  <SelectTrigger id="trigger-condition">
                    <SelectValue placeholder="Select trigger" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={'default'}>{triggerOptions.default}</SelectItem>
                    <SelectItem value={'daysOfWeek'}>{triggerOptions.daysOfWeek}</SelectItem>
                    <SelectItem value={'dates'}>{triggerOptions.dates}</SelectItem>
                  </SelectContent>
                </Select>

                {triggerValue && conditionInputs[triggerValue]}

                <RHFSlider form={form} name="brightness" label="Brightness" max={2} step={0.1} isPercentage />
                <RHFSlider form={form} name="contrast" label="Contrast" max={2} step={0.1} isPercentage />
              </div>
            </form>
          </ScrollArea>
        </FormProvider>

        <footer className="p-3 border-t-[1px]">
          <Button
            form="edit-model-form"
            type="submit"
            className="w-full"
            disabled={!form.formState.isDirty || form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? <LoadingSpinner /> : 'Save'}
          </Button>
        </footer>
      </div>

      {blocker.state === 'blocked' ? (
        <ConfirmationDialog
          open={blocker.state === 'blocked'}
          title="You have unsaved changes!"
          description={`Are you sure you want to leave? Your changes to the model: '${currentModel.displayName}' will be lost.`}
          cancelLabel="Cancel"
          continueLabel="Discard Changes"
          onContinue={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      ) : null}
    </>
  );
}
