import RestaurantService from '@/services/restaurant.service';
import { Override } from '@repo/types';
import { useQuery } from '@tanstack/react-query';

const useOverrides = (slug: string) => {
  return useQuery<{ restaurantId: string; overrides: Override[]}, Error>({
    queryKey: ['getOverrides', slug],
    queryFn: () => RestaurantService.getOverrides(slug),
  });
};

export { useOverrides };
