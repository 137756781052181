import { useRef, useState } from 'react';
import LoadingSpinner from '@/components/loading-spinner';
import TruncateText from '@/components/truncate-text';
import { getCdnImageUri, getCdnVideoUri } from '@/helpers/cdn.helper';
import { getFileNameFromUrl } from '@/helpers/common.helper';
import { Button, Card, CardContent } from '@ui/components';
import { Play, Trash } from 'lucide-react';
import { MediaType } from '@repo/types';

interface MediaUploadCardProps {
  path: string;
  title?: string;
  deleteAction: React.MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
  variant?: 'simple' | 'full';
  type?: MediaType;
  selected?: boolean;
}

export default function MediaUploadCard({
  path,
  title,
  deleteAction,
  isLoading,
  variant = 'full',
  type = MediaType.IMAGE,
  selected = false,
}: MediaUploadCardProps) {
  const fileName = getFileNameFromUrl(path);
  const displayTitle = title || fileName;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <Card
      className="w-[210px] h-[240px]"
      style={selected ? { border: '3px solid cyan', borderRadius: '4px' } : {}}
    >
      <CardContent className="relative h-full p-1 bg-tertiary flex flex-col">
        <div className="relative flex-grow">
          {type === MediaType.IMAGE ? (
            <img
              src={getCdnImageUri(path)}
              alt={displayTitle}
              className="object-cover w-[200px] h-[200px] rounded-md"
            />
          ) : (
            <div
              className="relative w-[200px] h-[200px] cursor-pointer"
              onClick={variant === 'full' ? handleVideoClick : () => {}}
            >
              <video
                ref={videoRef}
                src={getCdnVideoUri(path) + '#t=0.001'}
                className="object-cover w-[200px] h-[200px] rounded-md"
                loop
                playsInline
                muted
                preload="metadata"
                poster={`${getCdnVideoUri(path)}?poster`} // Use poster image
                title={displayTitle}
              />
              {!isPlaying && (
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                  <div className="bg-black bg-opacity-50 rounded-full p-2">
                    <Play size={24} color="white" />
                  </div>
                </div>
              )}
            </div>
          )}
          {variant === 'full' && (
            <div className="absolute top-1 right-1">
              <Button
                variant="destructive"
                size="icon"
                disabled={isLoading}
                onClick={deleteAction}
                type="button"
              >
                {isLoading ? <LoadingSpinner /> : <Trash size={16} />}
              </Button>
            </div>
          )}
        </div>
        <div className="mt-1 text-center">
          <TruncateText maxLength={15} text={displayTitle} />
        </div>
      </CardContent>
    </Card>
  );
}
