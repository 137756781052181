import ProtectedRoute from '@/components/protected-route';
import MainLayout from '@/layout/main-layout';
import AuthenticationPage from '@/pages/auth/login';
import GeneralError from '@/pages/errors/general-error';
import PageNotFoundError from '@/pages/errors/page-not-found';
import MatterportModels from '@/pages/matterport-models';
import CreateNewModel from '@/pages/matterport-models/create/create-new-model';
import EditModelSettings from '@/pages/matterport-models/edit/edit-model-settings';
import MatterportPins from '@/pages/matterport-pins';
import CreateNewPin from '@/pages/matterport-pins/create/create-new-pin';
import { Closures, Details, Location, Policies, Schedule } from '@/pages/matterport-pins/edit';
import EditPinSettings from '@/pages/matterport-pins/edit/edit-pin-settings';
import Fees from '@/pages/matterport-pins/edit/fees';
import { default as RestaurantPolicies } from '@/pages/restaurant-account/policies';
// import RestaurantDashboard from "@/pages/restaurant-dashboard";
import Overrides from '@/pages/overrides';
import EditOverride from '@/pages/overrides/edit';
import RestaurantAccount from '@/pages/restaurant-account';
import RestaurantFees from '@/pages/restaurant-account/fees';
import SpecialEvents from '@/pages/special-events';
import CreateNewEvent from '@/pages/special-events/create/create-event';
import UpdateEvent from '@/pages/special-events/edit/update-event';
import { DashboardRedirect } from '@/routes';
import { createBrowserRouter } from 'react-router-dom';
import MediaManagementPage from "@/pages/content";
import TableMediaConfiguration from "@/pages/matterport-pins/edit/media";

const router = createBrowserRouter([
  {
    path: '/sign-in',
    element: <AuthenticationPage />,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    errorElement: <GeneralError />,
    children: [
      {
        path: ':restaurantSlug',
        element: <DashboardRedirect />,
      },
      {
        path: ':restaurantSlug/dashboard',
        element: <DashboardRedirect />,
        // TODO:
        // element: <RestaurantDashboard />
      },
      {
        path: ':restaurantSlug/account',
        element: <RestaurantAccount />,
        children: [
          {
            path: 'media',
            element: <p>media</p>,
          },
          {
            path: 'schedule',
            element: <p>schedule</p>,
          },
          {
            path: 'policies',
            element: <RestaurantPolicies />,
          },
          {
            path: 'fees',
            element: <RestaurantFees />,
          },
          {
            path: 'closures',
            element: <p>closures</p>,
          },
          {
            path: 'taxes-payouts',
            element: <p>taxes-payouts</p>,
          },
          {
            path: 'integrations',
            element: <p>integrations</p>,
          },
          {
            path: 'feature-flags',
            element: <p>feature-flags</p>,
          },
        ],
      },
      {
        path: ':restaurantSlug/matterport-models',
        element: <MatterportModels />,
        children: [
          {
            path: ':modelId',
            element: <EditModelSettings />,
          },
          {
            path: 'create',
            element: <CreateNewModel />,
          },
        ],
      },   {
        path: ':restaurantSlug/content',
        element: <MediaManagementPage />,
      },

      {
        path: ':restaurantSlug/matterport-pins',
        element: <MatterportPins />,
        children: [
          {
            path: 'create',
            element: <CreateNewPin />,
          },
          {
            path: ':pinId',
            element: <EditPinSettings />,
            children: [
              {
                path: 'details',
                element: <Details />,
              },
              {
                path: 'media',
                element: <TableMediaConfiguration />,
              },
              {
                path: 'schedule',
                element: <Schedule />,
              },
              {
                path: 'closures',
                element: <Closures />,
              },
              {
                path: 'policies',
                element: <Policies />,
              },
              {
                path: 'fees',
                element: <Fees />,
              },
              {
                path: 'location',
                element: <Location />,
              },
            ],
          },
        ],
      },
      {
        path: ':restaurantSlug/overrides',
        element: <Overrides />,
        children: [
          {
            path: ':overrideId',
            element: <EditOverride />
          }
        ]
      },
      {
        path: ':restaurantSlug/special-events',
        element: <SpecialEvents />,
        children: [
          {
            path: ':eventId',
            element: <UpdateEvent />,
          },
          {
            path: 'create',
            element: <CreateNewEvent />,
          },
        ],
      },
    ],
  },

  // Error routes
  { path: '*', Component: PageNotFoundError },
]);

export default router;
