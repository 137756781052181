// useMediaCreate.ts
import MediaService from "@/services/media.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface CreateMediaMutationVariables {
    restaurantId: string;
    formData: FormData;
}

const useMediaCreate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ["createMedia"],
        mutationFn: (data: CreateMediaMutationVariables) => MediaService.createMedia(data),
        onSuccess: (_, { restaurantId }) => {
            queryClient.invalidateQueries({ queryKey: ["media", restaurantId] });
        },
        onError: (error) => {
            console.error("Error uploading media:", error);
        },
    });
};

export { useMediaCreate };
