import RHFSwitch from "@/components/form/rhf-switch";
import { capitalized } from "@/helpers/common.helper";
import { DAYS_OF_WEEK } from "@/helpers/form.helper";
import { DayOfWeek } from "@repo/types";
import { FieldError, Path, UseFormReturn } from "react-hook-form";

interface PolicyDaySelectorFormValues {
  condition: {
    selectedDays?: Record<DayOfWeek, boolean>;
  };
}

interface PolicyDayOfWeekSelectorProps<TFormData extends PolicyDaySelectorFormValues> {
  form: UseFormReturn<TFormData>;
}

const PolicyDayOfWeekSelector = <TFormData extends PolicyDaySelectorFormValues>({ form }: PolicyDayOfWeekSelectorProps<TFormData>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getSelectedDaysError = (errors: any): FieldError | undefined => {
    return errors?.condition?.selectedDays as FieldError | undefined;
  };
  const selectedDaysError = getSelectedDaysError(form.formState.errors);

  return (
    <>
      <div className="grid grid-cols-7 gap-4 sm:p-3">
        {DAYS_OF_WEEK.map((day) => (
          <div key={day}>
            <RHFSwitch
              form={form}
              name={`condition.selectedDays.${day}` as Path<TFormData>}
              label={capitalized(day)}
              className="grid gap-2 place-items-center"
            />
          </div>
        ))}
      </div>
      {selectedDaysError && (
        <p className="text-center text-red-600">
          {selectedDaysError.root?.message}
        </p>
      )}
    </>
  );
}

export default PolicyDayOfWeekSelector;