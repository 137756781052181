import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table';
import {
  DraggableTableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@ui/components';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataTableFacetedFilter, DataTableSearchFilter, DataTableToolbar } from './data-table-toolbar';

interface DataTableDraggableProps<TData, TValue> {
  selectedRowUrlParam?: string;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  searchFilters?: DataTableSearchFilter[];
  facetedFilters?: DataTableFacetedFilter[];
  showDeletedRecordsCheckbox?: boolean;
  onShowDeletedRecordsChange?: (checked: boolean) => void;
  onSaveOrder?: (data: TData[]) => void;
}

export function DataTableDraggable<TData extends { id: UniqueIdentifier }, TValue>({
  selectedRowUrlParam,
  columns,
  data,
  searchFilters,
  facetedFilters,
  showDeletedRecordsCheckbox,
  onShowDeletedRecordsChange,
  onSaveOrder,
}: DataTableDraggableProps<TData, TValue>) {
  const params = useParams();

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [draggableData, setDragableData] = useState(data);

  const dataIds = useMemo<UniqueIdentifier[]>(() => draggableData.map(({ id }) => id), [draggableData]);

  useEffect(() => {
    setDragableData(data);
  }, [data]);

  const table = useReactTable({
    data: draggableData,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    getRowId: (row) => {
      if (!row.id) {
        console.error('MISSING ROW ID: ', {row})
        return ''
      }
      return row.id.toString()
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setDragableData((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);
        const updatedData = arrayMove(data, oldIndex, newIndex);

        // Immediately call onSaveOrder with the updated order
        onSaveOrder?.(updatedData);

        return updatedData; // Return updated data to update state
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const showToolbar = !!searchFilters || !!facetedFilters || showDeletedRecordsCheckbox;

  return (
    <div className="flex flex-col pb-8 space-y-4">
      {showToolbar && (
        <DataTableToolbar
          {...{
            table,
            searchFilters,
            facetedFilters,
            showDeletedRecordsCheckbox,
            onShowDeletedRecordsChange,
          }}
        />
      )}

      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div className="border rounded-md">
          <Table>
            <TableHeader className="bg-zinc-200 dark:bg-zinc-800">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                      <DraggableTableRow
                        rowId={row.id}
                        key={row.id}
                        data-state={
                          (row.getIsSelected() ||
                            (selectedRowUrlParam && params[selectedRowUrlParam] === row.id)) &&
                          'selected'
                        }
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </DraggableTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </SortableContext>
            </TableBody>
          </Table>
        </div>
      </DndContext>
    </div>
  );
}
