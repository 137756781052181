import { generalUpdateFail, generalUpdateSuccess } from '@/components/toasts';
import RestaurantService from '@/services/restaurant.service';
import { Override, Restaurant } from '@repo/types';
import { useToast } from '@repo/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useOverridesUpdate = (id?: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Restaurant, Error, { id: string; data: Override[] }>({
    mutationKey: ['updateOverrides', id],
    mutationFn: ({ id, data }) => RestaurantService.updateOverrides(id, data),
    onSuccess: (data) => {
      toast(generalUpdateSuccess(`Saved overrides at ${data.displayName}`));

      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['getOverrides', data.slug] }),
      ]);
    },
    onError: (error) => {
      console.error(error);
      toast(generalUpdateFail);
    },
  });
};

export { useOverridesUpdate };
