import { CutoffType, CutoffUnit, DayOfWeek, DisplayLocation, ItemType, Weekday } from "./common";

export enum PolicyType {
  // Policies
  BOOKING_LEAD_TIME = "BookingLeadTime",
  BOOKING_HORIZON = "BookingHorizon",
  GRACE_PERIOD = "BookingGracePeriod",
  MODIFICATION = "CutoffModification",
  CANCELLATION = "CutoffCancellation",
  REQUEST_RESPONSE_TIME = "RequestResponseTime",
  // Fees
  SELECTION_FEE = "SelectionFee",
  DEPOSIT = "Deposit",
  MIN_SPEND = "MinimumSpend",
  NO_SHOW = "NoShow",
  GRATUITY = "Gratuity",
}

export interface ConditionValue {
  daysOfWeek?: DayOfWeek[];
  dates?: string[], // a list of dates, formatted 'MMMM dd, yyyy'
	betweenDates?: [string, string],
  betweenTimes?: [string, string]; // time strings, ie '16:00', '5:45 pm'
  hasItem?: { type?: ItemType; id?: string };
  not?: ConditionValue;
  or?: ConditionValue[];
  and?: ConditionValue[];
}

export interface Condition {
  id: string;
  value: ConditionValue;
  weight: number;
  policy: string;
}

export interface CreateCondition {
  value: ConditionValue;
  weight: number;
}

export interface UpdateCondition {
  value?: ConditionValue;
  weight?: number;
}

export interface Display {
  id: string;
  policy: string;
  title?: string;
  description: string;
  location: DisplayLocation;
  icon: string;
}

export type CreateDisplay = Omit<Display, "id" | "policy">;

export interface UpdateDisplay {
  title?: string;
  description?: string;
  location?: DisplayLocation;
  icon?: string;
}

export interface Cutoff {
  id: string;
  type: CutoffType;
  value?: numberm | null;
  unit?: CutoffUnitm | null;
  timeOfDay?: stringm | null;
  policy: string;
}

export interface CreateCutoff {
  type?: CutoffType;
  value?: number | null;
  unit?: CutoffUnit | null;
  timeOfDay?: string | null;
}

export interface UpdateCutoff {
  type?: CutoffType;
  value?: number | null;
  unit?: CutoffUnit | null;
  timeOfDay?: string| null;
}

export interface Policy {
  id: string;
  name: string;
  description: string;
  type: PolicyType | string;
  is_custom?: boolean;
  restaurantId: string;
  tableId?: string;
  condition?: Condition;
  displays: Display[];
  isPlaceholder: boolean;
}

export interface PolicyWithCutoff extends Policy {
  cutoff: Cutoff;
}

export type CreatePolicyRequest = Omit<
  Policy,
  "id" | "displays" | "condition" | "restaurantId" | "tableId" | "isPlaceholder"
> & {
  displays?: CreateDisplay[];
  cutoff?: CreateCutoff;
  condition?: CreateCondition | {};
};

export type UpdatePolicyRequest = Omit<
  Policy,
  "displays" | "cutoff" | "condition" | "restaurantId" | "tableId"
> & {
  displays?: UpdateDisplay[];
  condition?: UpdateCondition;
  cutoff?: UpdateCutoff;
};

export type PolicyKey = keyof Policy;
export type PolicyValue = Policy[PolicyKey];
