import { useState } from "react";
import { ScrollArea } from "@ui/components";
import MediaUploadCard from "@/components/media-upload-card";
import { useMedia } from "@/hooks/useMedia";
import FileUploader from "./FileUploader";
import { useMediaCreate } from "@/hooks/useMediaCreate";
import { useMediaDelete } from "@/hooks/useMediaDelete";
import LoadingSpinner from "@/components/loading-spinner";
import { ConfirmationDialog } from "@/components/confirmation-dialog";
import { useParams } from "react-router-dom";
import { Button } from "@repo/ui";
import { useRestaurantBySlug } from "@/hooks/useRestaurantBySlug";
import { useMediaUpdate } from "@/hooks/useMediaUpdate";
import { Panel, PanelGroup } from "react-resizable-panels";
import ResizeHandle from "@/components/resize-handle/resize-handle";
import UpdateMedia from "./edit/update-media";
import {Media} from "@repo/types";
import PageHeader from "@/components/page-header";

export default function MediaManagement() {
    const params = useParams();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [imgPendingDelete, setImgPendingDelete] = useState<string>("");
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<Media | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    if (!params.restaurantSlug) {
        throw new Error("Media Management page requires a restaurantSlug URL param");
    }

    const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
    const restaurantId = restaurantQuery?.data?.id || "";
    const mediaQuery = useMedia(restaurantId);
    const mediaList = mediaQuery.data || [];

    const createMediaMutation = useMediaCreate();
    const deleteMediaMutation = useMediaDelete();
    const updateMediaMutation = useMediaUpdate();

    const handleUpload = async () => {
        if (!selectedFiles.length) return;
        setIsUploading(true)

        try {
            const uploadPromises = selectedFiles.map((file) => {
                const formData = new FormData();
                formData.append("file", file);

                return createMediaMutation.mutateAsync({
                    restaurantId,
                    formData,
                });
            });

            await Promise.all(uploadPromises);

            mediaQuery.refetch(); // Refresh media list
            handleSidebarClose();
            setSelectedFiles([]);
        } catch (error) {
            console.error("Error uploading files:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const onDeleteClick = (id: string) => {
        setAlertOpen(true);
        setImgPendingDelete(id);
    };

    const handleDelete = async () => {
        try {
            setAlertOpen(false);
            await deleteMediaMutation.mutateAsync({ id: imgPendingDelete, restaurantId });
            mediaQuery.refetch();
        } catch (error) {
            console.error("Error deleting media:", error);
        } finally {
            setImgPendingDelete("");
        }
    };

    const onMediaClick = (mediaItem: Media) => {
        if (selectedMedia?.id === mediaItem.id) {
            handleSidebarClose();
        } else {
            setSelectedMedia(mediaItem);
            setIsSidebarVisible(true);
        }
    };

    const handleSidebarClose = () => {
        setIsSidebarVisible(false);
        setSelectedMedia(null);
    };

    const handleUpdateMedia = async (updatedMedia: Media) => {
        try {
            await updateMediaMutation.mutateAsync({
                id: updatedMedia.id,
                restaurantId,
                title: updatedMedia.title,
                tags: updatedMedia.tags,
            });

            mediaQuery.refetch();
            handleSidebarClose();
        } catch (error) {
            console.error("Error updating media:", error);
        }
    };

    return (
        <PanelGroup direction="horizontal" autoSaveId="media-management-page">
            <Panel defaultSize={70}>
                <PageHeader title="Content Manager" />
                <div className="flex flex-col gap-4 h-full px-5 py-3">
                    <FileUploader onFilesSelected={setSelectedFiles} />
                    {selectedFiles.length > 0 && (
                        <Button variant="secondary" onClick={handleUpload}>
                            {isUploading ? <LoadingSpinner /> : "Upload Selected Files"}
                        </Button>
                    )}

                    <ScrollArea className="flex-1 overflow-y-auto">
                        <div className="flex flex-wrap gap-4">
                            {mediaList.map((item) => (
                                <div key={item.id} onClick={() => onMediaClick(item)} className="cursor-pointer">
                                    <MediaUploadCard
                                        path={item.url}
                                        title={item.title}
                                        type={item.type}
                                        deleteAction={(e) => {
                                            e.stopPropagation();
                                            onDeleteClick(item.id)
                                        }}
                                        variant="full"
                                        isLoading={
                                            deleteMediaMutation.isPending && deleteMediaMutation.variables?.id === item.id
                                        }
                                        selected={selectedMedia?.id === item.id}
                                    />
                                </div>
                            ))}
                        </div>
                    </ScrollArea>
                </div>
            </Panel>

            <ResizeHandle />

            {selectedMedia && (
            <UpdateMedia
                selectedMedia={selectedMedia}
                isVisible={isSidebarVisible}
                onClose={handleSidebarClose}
                onSave={handleUpdateMedia}
            />
            )}

            <ConfirmationDialog
                open={alertOpen}
                title="Confirm Deletion"
                description="Are you sure you want to delete this media item?"
                cancelLabel="Cancel"
                continueLabel="Delete"
                onCancel={() => {
                    setAlertOpen(false);
                    setImgPendingDelete("");
                }}
                onContinue={handleDelete}
            />
        </PanelGroup>
    );
}
