import DatesInput from '@/components/constraint-inputs/dates-input';
import PolicyDayOfWeekSelector from '@/components/form/day-of-week-selector/policy-day-of-week-selector';
import RHFSelect from '@/components/form/rhf-select';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import BasePolicyCard from '@/components/policies/cards/base-policy-card';
import { BookingLeadTimeFormData, bookingLeadTimeFormSchema } from '@/components/policies/cards/schemas';
import { CutoffUnitOptions } from '@/config/dropdown-options/cutoffs';
import {
  DEFAULT_POLICY_ICON,
  PolicyDefaultDisclaimerMap,
  PolicyIconMap,
} from '@/constants/policies.constants';
import {
  getBetweenTimesCondition,
  getDayOfWeekCondition,
  getHasItemCondition,
  getInitialBetweenTimes,
  getInitialDaysOfWeek,
  getInitialDisclaimer,
  transformCutoff,
} from '@/helpers/form.helper';
import { APPLIES_ON_OPTIONS, AppliesOnOptionKeys, useAppliesOnHandlers } from '@/hooks/useAppliesOnHandlers';
import {
  CreatePolicyRequest,
  CutoffType,
  CutoffUnit,
  DisplayLocation,
  PolicyType,
  PolicyWithCutoff,
  UpdatePolicyRequest,
} from '@repo/types';
import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/components';
import * as React from 'react';

interface BookingLeadTimeCardProps {
  policy: PolicyWithCutoff;
  maxWeight: number;
}

const BookingLeadTimeCard: React.FC<BookingLeadTimeCardProps> = ({ policy, maxWeight }) => {
  const defaultAppliesOn = (policy.condition?.value.dates ?? []).length > 0 ? 'dates' : 'daysOfWeek';

  const { appliesOn, handleAppliesOnChange, handleSelectedDatesChange } =
    useAppliesOnHandlers<BookingLeadTimeFormData>(defaultAppliesOn);

  const formDefaultValues: BookingLeadTimeFormData = {
    cutoff: {
      value: policy.cutoff.value || 0,
      unit: policy.cutoff.unit || CutoffUnit.NONE,
      timeOfDay: policy.cutoff.timeOfDay || '',
    },
    disclaimer: getInitialDisclaimer(policy, PolicyDefaultDisclaimerMap[policy.type as PolicyType] || ''),
    condition: {
      selectedDays: getInitialDaysOfWeek(policy),
      betweenTimes: getInitialBetweenTimes(policy),
      dates: policy.condition?.value.dates || [],
    },
  };

  const getPolicyCreateData = (formData: BookingLeadTimeFormData): CreatePolicyRequest => ({
    name: PolicyType.BOOKING_LEAD_TIME,
    description: PolicyType.BOOKING_LEAD_TIME,
    type: PolicyType.BOOKING_LEAD_TIME,
    cutoff: transformCutoff(formData.cutoff, CutoffType.CREATION),
    displays: [
      {
        title: 'Lead Time',
        location: DisplayLocation.OTHER,
        icon: PolicyIconMap[PolicyType.BOOKING_LEAD_TIME] || DEFAULT_POLICY_ICON,
        description: formData.disclaimer,
      },
    ],
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
      weight: maxWeight + 1,
    },
  });

  const getPolicyUpdateData = (formData: BookingLeadTimeFormData): UpdatePolicyRequest => ({
    ...policy,
    id: policy.id,
    cutoff: transformCutoff(formData.cutoff, CutoffType.CREATION),
    displays: policy.displays.map((display) => ({
      ...display,
      description: formData.disclaimer,
    })),
    condition: {
      value: {
        ...(formData.condition.selectedDays ? getDayOfWeekCondition(formData) : undefined),
        ...getBetweenTimesCondition(formData),
        ...getHasItemCondition(policy),
        dates: formData.condition.dates,
      },
    },
  });

  return (
    <BasePolicyCard<BookingLeadTimeFormData>
      formSchema={bookingLeadTimeFormSchema}
      formDefaultValues={formDefaultValues}
      policy={policy}
      policyType={PolicyType.BOOKING_LEAD_TIME}
      getPolicyCreateData={getPolicyCreateData}
      getPolicyUpdateData={getPolicyUpdateData}
    >
      {({ form }) => (
        <>
          <div className="flex items-center gap-4">
            <Label className="text-nowrap" htmlFor="day-selector-condition">
              Applies on
            </Label>
            <Select
              defaultValue={APPLIES_ON_OPTIONS.daysOfWeek}
              value={appliesOn}
              onValueChange={(value) => {
                handleAppliesOnChange(form, value as AppliesOnOptionKeys);
              }}
            >
              <SelectTrigger id="day-selector-condition">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={'daysOfWeek'}>{APPLIES_ON_OPTIONS.daysOfWeek}</SelectItem>
                <SelectItem value={'dates'}>{APPLIES_ON_OPTIONS.dates}</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {appliesOn === 'dates' && (
            <DatesInput
              form={form}
              name="condition.dates"
              handleSelectedDatesChange={(dates) => handleSelectedDatesChange(form, dates)}
            />
          )}

          {appliesOn === 'daysOfWeek' && <PolicyDayOfWeekSelector form={form} />}

          <div className="flex w-full gap-4">
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.0"
              label="Start"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              className="flex-1"
              form={form}
              name="condition.betweenTimes.1"
              label="End"
              inputProps={{ type: 'time' }}
            />
          </div>

          <div className="grid gap-4 mb-4 sm:grid-cols-3">
            <RHFTextInput
              form={form}
              name="cutoff.timeOfDay"
              label="Time of Day"
              inputProps={{ type: 'time' }}
            />
            <RHFTextInput
              form={form}
              name="cutoff.value"
              label="Value"
              inputProps={{ type: 'number', min: 0 }}
            />
            <RHFSelect form={form} name="cutoff.unit" label="Unit" options={CutoffUnitOptions} />
          </div>

          <RHFTextArea form={form} name="disclaimer" label="Disclaimer" />
        </>
      )}
    </BasePolicyCard>
  );
};

export default BookingLeadTimeCard;
